.u-full-width {
  width: 100%;
}

.u-width-100p {
  width: 100%;
}

.u-full-max-width {
  max-width: 100% !important;
}
