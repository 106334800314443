.flickity-page-dots {
  &:empty {
    display: none;
  }

  &.counter {
    //display: block;
    //height: 6rem;
    //line-height: 6rem;

    .dot {
      display: none;
    }

    .slideshow-counter {
      font-weight: 700;
      color: var(--amc-text-disabled);
    }
  }
}
