.content--video {
  iframe {
    max-width: 100%;
    //width: 100%;
  }
}
//
//.content-video__iframe-wrapper {
//  position: relative;
//  overflow: hidden;
//
//}

.content-video__iframe {
  //
}

.content-video__iframe-wrapper {
  position: relative;
  overflow: hidden;

  iframe,
  .content-video__iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    //width: 100%;
    height: 100%;
    border: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    max-width: 100%;
    width: 1px;
    min-width: 100%;
    /* stylelint-disable */
    *width: 100%;
    /* stylelint-enable */
  }
}

.content-video__iframe__sizer {
  width: 100%;
  display: block;
}

.video-container {
  position: relative;

  .buttons {
    position: absolute;
    margin-top: 5px;
    display: flex;
    //background: var(--amc-LandingPageMenuActive);
    //color: #FFFFFF;
    justify-content: flex-end;
    width: auto;
    right: 0;
    bottom: 0;

    .video-button {
      outline: none;
      border: none;
      background: rgba(255,255,255,0.2);
      padding: 0;
      cursor: pointer;
      color: var(--amc-white);

      svg {
        display: block;
        fill: currentColor;
      }

      &:hover, &:active {
        background: var(--amc-menuActive);
        //color: var(--amc-menuActive);
      }

      &.pause-video {
        .is-for-pause-on {
          display: none;
        }
        .is-for-pause-off {
          display: block;
        }

        &.is-paused {
          .is-for-pause-on {
            display: block;
          }
          .is-for-pause-off {
            display: none;
          }
        }
      }
      &.unmute-video {
        .is-for-sound-on {
          display: none;
        }
        .is-for-sound-off {
          display: block;
        }

        &.is-muted {
          .is-for-sound-on {
            display: block;
          }
          .is-for-sound-off {
            display: none;
          }
        }
      }
    }

    @include mq($from: tablet) {
      position: absolute;
      //bottom: $grid-width * 0.25;
      //right: $grid-width * 0.25;
      //gap: $grid-width * 0.25;

      &.is-vertical {
        flex-direction: column;
      }
    }
  }
}
