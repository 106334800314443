.site-main {
  overflow: hidden;
}

.content-block {
  @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 50px, 100px);

  position: relative;
}

.half-padding {
  @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 25px, 50px);
}

.content-title {
  @include fluid-type(padding-top padding-bottom, map-get($mq-breakpoints, 'tabletoffset'), map-get($mq-breakpoints, 'desktop'), $grid-gutter-width * 0.25, $grid-gutter-width);
  //@include fluid-type(padding-right padding-left, map-get($mq-breakpoints, 'tabletoffset'), map-get($mq-breakpoints, 'desktop'), 20px, 100px);
  @include fluid-type(padding-right padding-left, map-get($mq-breakpoints, 'tabletoffset'), map-get($mq-breakpoints, 'desktop'), $grid-gutter-width * 0.25, $grid-gutter-width );

  //width: calc(100% - 30px);
  //margin: -100px 15px 70px;
  background: var(--amc-white);
  border: 2px solid var(--amc-border);
  margin: 0;
  width: 100%;

  .overline {
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.5px;
    padding-bottom: $grid-width * 0.25;
    display: block;
    color: var(--amc-focus);
    //text-transform: uppercase;
  }

  .title {
    @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 24px, 64px);

    height: auto;
    //max-height: calc(1em * 1.2 * 2);
    overflow: hidden;
    color: var(--amc-text);
    text-transform: uppercase;
    //word-break: break-all;

    @include mq($until: 'phablet') {
      max-height: calc(1em * 1.2 * 4);
    }

    &.is-long {
      @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 24px, 48px);
    }
  }
}

//.content-title {
//  @include fluid-type(padding-right padding-left, map-get($mq-breakpoints, 'tabletoffset'), map-get($mq-breakpoints, 'desktop'), 20px, $grid-gutter-width * 2);
//
//  margin: 0;
//  width: 100%;
//
//  .overline {
//    //color: var(--amc-focus);
//    //font-size: $grid-width / 4;
//    //text-transform: uppercase;
//  }
//
//  .title {
//    @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 24px, 64px);
//
//    height: auto;
//    //max-height: calc(1em * 1.2 * 2);
//    //overflow: hidden;
//    color: var(--amc-text);
//    text-transform: uppercase;
//    //word-break: break-all;
//
//    @include mq($until: 'phablet') {
//      max-height: calc(1em * 1.2 * 4);
//    }
//  }
//}

.content-block {
  &.with-hero-container {
    margin-bottom: $grid-gutter-width * 3.5;
    //height: 60vh;

    @include mq($until: tabletoffset) {
      margin-bottom: $grid-width;
    }

    //@include mq($until: 'phablet') {
    //  margin-bottom: $grid-gutter-width * 2;
    //}
  }
}

.content-hero {
  position: relative;
  width: 100%;
  //height: 70vh;
  //max-height: $grid-width * 10;
  color: #fff;
  background-color: #eee;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  .content-hero-image {
    width: 100%;
  }
}

.content-hero-content {
  @include fluid-type(padding, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 18px, 50px);

  position: absolute;
  max-width: 100%;
  overflow: hidden;
}

.content-hero-quote {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 24px, 40px);

  letter-spacing: 1px;

  @include font-quote;
}

.content-hero-title {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 20px, 64px);
  @include fluid-type(max-width, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 68%, 68%);
  @include font-heavy;

  font-weight: 900;
  width: 100%;
  line-height: 1.2;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.ctcc {
  position: relative;
}

.ctcc-inner {
  @include mq($until: tabletoffset) {
    position: relative;
    width: 100%;
  }
}

.content-title-container {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  transform: translateY(-50%);

  @include mq($until: tabletoffset) {
    position: static;
    top: 0;
    transform: none;
  }
}
