.u-no-margin {
  &-bottom {
    margin-bottom: 0 !important;
  }
}

.u-margin {
  &-bottom {
    margin-bottom: $baseline !important;

    &--double {
      margin-bottom: $baseline * 2 !important;
    }
  }

  &-top {
    margin-top: $baseline !important;

    &--double {
      margin-top: $baseline * 2 !important;
    }
  }

  &-right {
    margin-right: $baseline !important;
  }

  &-left {
    margin-right: $baseline !important;
  }
}

.u-m0 {
  margin: 0 !important;
}
