.checks {
  font-size: 14px;
  border: 2px solid var(--amc-border);
  border-bottom: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.checks-item {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  padding: 15px 50px 15px 15px;
  border-bottom: 2px solid var(--amc-border);

  @include font-bold;

  &:after {
    position: absolute;
    font-size: 20px;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border-left: 2px solid var(--amc-border);
    content: '';
  }
}
