.news-container {
  //
}

.news-headline {
  @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 50px, 100px);

  position: relative;
}

.news-list {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 100%;

  &.regular-layout {
    .news-item {
      width: 100%;
      margin: 0 0 30px;
      //margin: 0;

      &:nth-child(1n),
      &:nth-child(2n) {
        .news-item-image {
          order: 1;
        }

        .news-item-content {
          order: 2;
        }
      }
    }
  }
}

.news-item {
  //@include fluid-type(width, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 100%, 75%);

  background: #fff;
  //margin: 0;
  min-height: 300px;
  outline: 2px solid var(--amc-border);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  margin: 0 0 $grid-width * 0.5;

  @include mq($until: 'tabletoffset') {
    width: 100%;
    margin-bottom: $grid-width * 0.5;
  }

  @include mq($until: 'tablet') {
    flex-flow: row wrap;
    margin-bottom: $grid-width * 0.25;

    //.news-item-image {
    //  display: none;
    //}
  }

  &:last-child {
    margin-bottom: 0;
  }

  //@include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
  //  margin: 0 !important;
  //
  //  &.card-story-more {
  //    height: auto;
  //    min-height: 0;
  //  }
  //}
  //&:nth-child(1n) {
  //  @include fluid-type(margin-left, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 0%, 25%);
  //
  //  float: right;
  //  margin-right: 0;
  //
  //  .news-item-image {
  //    order: 1;
  //  }
  //
  //  .news-item-content {
  //    order: 2;
  //  }
  //}
  //
  //&:nth-child(2n) {
  //  @include fluid-type(margin-right, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 0%, 25%);
  //
  //  float: left;
  //  margin-left: 0;
  //
  //  .news-item-image {
  //    order: 2;
  //
  //    @include mq($until: map-get($mq-breakpoints, 'tablet')) {
  //      order: 1;
  //    }
  //  }
  //
  //  .news-item-content {
  //    order: 1;
  //
  //    @include mq($until: map-get($mq-breakpoints, 'tablet')) {
  //      order: 2;
  //    }
  //  }
  //}

  .news-item-pretitle {
    @include font-bold;

    font-size: 17px;
    letter-spacing: 0.5px;
  }

  .news-item-title-link {
    color: var(--amc-text);

    &:hover {
      color: var(--amc-text);
      text-decoration: none;
    }
  }

  .news-item-title {
    margin: 10px 0;
  }

  &.news-item-more {
    height: 300px;
    margin-top: 50px;
    padding-bottom: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: var(--amc-brand1);
    flex-direction: column;
    border: 2px solid var(--amc-border);
    outline: none;

    .text {
      @include font-heavy;

      position: relative;
      margin-top: 30px;
      margin-bottom: 30px;
      color: var(--amc-black);
      text-transform: uppercase;
      display: block;
    }

    @include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
      height: auto;
      min-height: 0;
    }

    @include mq($until: 'phablet') {
      margin-top: 0;
    }
  }
}

.news-item-image {
  position: relative;
  height: 100%;
  display: flex;
  //flex: 0 0 300px;
  background-position: center center;
  background-size: cover;
  flex: 0 0 50%;
  max-width: calc(50% - #{$grid-width * 0.5});

  @include mq($until: 'phablet') {
    order: 1;
    flex: 0 0 100%;
    height: 0;
    padding-bottom: 75%;
    max-width: 100%;
  }
}

.news-item-content {
  @include fluid-type(padding-top padding-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 30px);
  //@include fluid-type(padding-right padding-left, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 50px);

  padding-left: $grid-width;
  padding-right: $grid-width;
  flex: 1;

  @include mq($until: map-get($mq-breakpoints, 'tablet')) {
    order: 2;
  }

  @include mq($until: 'phablet') {
    padding-left: $grid-width * 0.5;
    padding-right: $grid-width * 0.5;
  }
}
