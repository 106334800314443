//.search__results-container {
//  position: absolute;
//  background-color: var(--amc-focus);
//  z-index: 1000;
//  //opacity: 0.5;
//  width: 100vw;
//  top: 1.234rem * 2;
//  left: - 1.234rem * 2;
//
//  @include mq($from: phablet) {
//    left: 50%;
//    transform: translateX(-50%);
//    top: 1.234rem * 4;
//  }
//}

.search-form {
  //@include fluid-type(margin-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 15px, 40px);
  //@include fluid-type(padding, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 15px, $grid-width);
  //padding-top: 0 !important;
  padding: 0;
  width: 100%;
  background: var(--amc-brand1);
  overflow: hidden;
}

.search-form-input {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.3px;
  //width: calc(100% - #{$grid-width / 2});
  width: calc(100% - #{$grid-width});
  line-height: $grid-width * 0.5;
  padding: $grid-width * 0.25;
  background: var(--amc-white);
  border: none;
  //display: inline-block;
  float: right;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;

  @include mq($from: tablet) {
    padding: $grid-width * 0.25 $grid-width * 0.5;
    //width: calc(100% - #{$grid-width});
  }
}

.search-form-button {
  width: $grid-width;
  height: $grid-width;
  color: var(--amc-white);
  background: var(--amc-brand3);
  border: none;
  //display: inline-block;
  float: right;
  cursor: pointer;

  &:hover {
    //background: var(--amc-brand3);
    background: var(--amc-brand1);
  }

  @include mq($from: tablet) {
    //width: $grid-width;
    float: left;
  }
}

.search-result-item {
  text-decoration: none;
  border-bottom: 1px solid var(--amc-menuActive);
  display: block;
  opacity: 0.8;
  padding: $grid-width * 0.25 $grid-width * 0.25;

  @include mq($from: tabletoffset) {
    padding: $grid-width * 0.25 $grid-width * 0.5;
  }

  &:hover {
    opacity: 1;
    background-color: var(--amc-menuActive);
    color: var(--amc-text);
  }

  .search-result-image {
    padding-left: 1.234rem / 2;
    display: block;
  }

  .search-result-name {
    display: block;
    font-weight: 900;
    text-transform: uppercase;
  }

  .search-result-headline {
    display: block;
  }
}

.search-results-container {
  @include mq($from: tabletoffset) {
    padding-left: $grid-width;
  }
}

.search-results {
  border-top: var(--amc-menuActive);
}

.search__products,
.search__others {
  margin-bottom: 0;
}

.search__section {
  margin-bottom: 0;

  .search__section__content {
    display: none;
  }

  &.is-open {
    margin-bottom: 1.234rem;

    .search__section__content {
      display: block;
    }
  }

  @include mq($from: tablet) {
    margin-bottom: 1.234rem * 2;

    &.is-open {
      margin-bottom: 1.234rem * 2;
    }

    .search__section__content {
      display: block;
    }
  }
}

.search__products,
.search__others {
  .grid__col {
    @include mq($until: phablet) {
      margin-bottom: 0;
    }
  }
}

.search__suggest-header {
  font-weight: 600;
  border-bottom: 1px solid var(--amc-focus);
  line-height: 1.234rem * 2;
  //padding-left: 1.234rem;
  //padding-right: 1.234rem;
  //margin-bottom: 1.234rem;
}

.search__suggest-list__item {
  text-decoration: none;
  border-bottom: 1px solid var(--amc-focus);
  padding-top: 1.234rem / 2;
  padding-bottom: 1.234rem / 2;
  display: block;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    background-color: var(--amc-focus);
  }

  .item__image-wrapper {
    padding-left: 1.234rem / 2;
    display: block;
  }

  .item__brand {
    display: block;
  }

  .item__title {
    display: block;
  }
}
