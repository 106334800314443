.site-header {
  //@include fluid-type(padding-top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 20px, 40px);
  @include fluid-type(padding-bottom padding-top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 15px, 30px);

  background-color: var(--amc-site-background);
  z-index: 100;
  width: 100%;
  transition: padding $transition-time $transition-fx;

  // class added by stickybits js
  &.js-is-sticky {
    border-bottom: 2px solid var(--amc-borders);
    padding-top: 0;
    padding-bottom: 0;

    .logo {
      //@include fluid-type(width, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 170px, 340px);
      //@include fluid-type(height, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 45px, 90px);
      width: 170px;
      height: 45px;
      margin-right: auto;

      @include mq($from: 'tablet', $until: 812px) {
        margin-right: $grid-width * 0.25;
      }
    }

    .nav-opener {
      width: $grid-width;
      height: $grid-width;
      font-size: 11px;
      letter-spacing: 0;
      padding: 0;

      .nav-opener-opened,
      .nav-opener-closed {
        width: 32px;
        height: 32px;
      }
    }

    .search-opener {
      width: $grid-width;
      height: $grid-width;
      font-size: 11px;
      letter-spacing: 0;
      padding: 0;

      .search-opener-opened,
      .search-opener-closed {
        width: 32px;
        height: 32px;
      }

      .search-opener-closed {
        .icon {
          max-width: 40px;
        }
      }
    }

    .headmap,
    .headnews,
    .headlink {
      .icon {
        width: 36px;
        height: 36px;
      }

      .headmap-text,
      .headnews-text,
      .headlink-text {
        line-height: 1.2;
      }
    }
  }
}

.site-header-flexer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.logo {
  @include fluid-type(width, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 170px, 340px);
  @include fluid-type(height, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 45px, 90px);

  transition: width height $transition-time $transition-fx;
  margin-right: auto;

  //@include mq($from: 'tablet', $until: 'desktop') {
  //  margin-right: $grid-width * 0.25;
  //}
}

.headsocials-list {
  //@include fluid-type(margin-top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 8px, 25px);
  margin-top: 0;
  margin-right: $grid-width * 0.5;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  float: right;
  display: flex;

  @include mq($until: 'smalldesktop') {
    display: none;
  }
}

.headsocials-item {
  display: inline-block;
  float: left;
}

.headsocials-link {
  margin: 4px 8px;
  color: #9b9b9b;
  display: block;
  width: 27px;
  height: 27px;

  .icon {
    width: 27px;
    height: 27px;
    display: block;
  }
}

.nav-opener {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 11px, 14px);
  @include fluid-type(letter-spacing, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 0, 2.3px);
  @include fluid-type(width height, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 50px, 115px);
  //@include fluid-type(margin-top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 1px, -10px);
  @include fluid-type(padding, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 0, 15px);

  position: relative;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--amc-white);
  background: var(--amc-brand1);
  float: right;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: all $transition-time $transition-fx;
  flex-shrink: 0;
  fill: var(--amc-white);

  &:hover {
    background-color: var(--amc-menuActive);
  }

  .nav-opener-opened,
  .nav-opener-closed {
    @include fluid-type(width height, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 32px, 64px);

    margin-right: auto;
    margin-left: auto;
    transition: all $transition-time $transition-fx;
  }

  .nav-opener-opened {
    display: none;
  }

  .nav-opener-text {
    text-align: center;

    @include mq($until: 'tablet') {
      display: none;
    }
  }

  &.is-opened {
    &:before {
      position: absolute;
      right: 0;
      bottom: -30px;
      left: 0;
      height: 30px;
      background: var(--amc-brand1);
      content: '';
    }

    .nav-opener-closed {
      display: none;
    }

    .nav-opener-opened {
      display: block;
    }
  }
}

.search-opener {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 11px, 14px);
  @include fluid-type(letter-spacing, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 0, 2.3px);
  @include fluid-type(width height, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 50px, 115px);
  //@include fluid-type(margin-top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 1px, -10px);
  @include fluid-type(padding, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 0, 15px);

  position: relative;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--amc-text);
  background: var(--amc-white);
  float: right;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: all $transition-time $transition-fx;
  flex-shrink: 0;
  fill: var(--amc-white);

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--amc-borders);
    opacity: 0;
    transition: opacity $transition-time $transition-fx;
  }

  &:hover {
    color: var(--amc-brand1);

    &:after {
      opacity: 1;
    }
  }

  .search-opener-opened,
  .search-opener-closed {
    @include fluid-type(width height, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 32px, 64px);

    margin-right: auto;
    margin-left: auto;
    transition: all $transition-time $transition-fx;
    display: flex;
    justify-content: center;
  }

  .search-opener-opened {
    display: none;
  }

  .search-opener-text {
    text-align: center;

    @include mq($until: 'tablet') {
      display: none;
    }
  }

  &.is-opened {
    &:before {
      position: absolute;
      right: 0;
      bottom: -30px;
      left: 0;
      height: 30px;
      background: var(--amc-brand1);
      content: '';
    }

    .search-opener-closed {
      display: none;
    }

    .search-opener-opened {
      display: block;
    }
  }
}

.headcol-title {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 20px, 24px);
  @include fluid-type(letter-spacing, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 0.4px, 1.6px);
  @include fluid-type(margin-top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 5px, 35px);
  @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 15px, 35px);

  font-weight: 900;
  text-transform: uppercase;
  color: var(--amc-white);
}

.headcol-list {
  margin: 0;

  @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 40px, 1px);

  padding: 0;
  list-style: none;
}

.headcol-item {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1.1px;
  display: block;
}

.headcol-link {
  @include fluid-type(padding-top padding-bottom, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 8px, 5px);

  color: var(--amc-white);
  display: block;

  &:hover {
    color: var(--amc-brand3);
    text-decoration: none;
  }
}

.headmap,
.headnews,
.headlink {
  //@include fluid-type(margin-top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), -5px, 15px);
  margin-top: 0;
  //float: right;
  margin-right: $grid-width * 0.5;
  display: flex;
  align-items: center;
  flex-shrink: 10;

  @include mq($until: 'tablet') {
    display: none;
  }

  @include mq($until: 'desktop') {
    margin-right: $grid-width * 0.25;
  }

  .icon {
    width: 57px;
    height: 56px;
    margin-right: 15px;

    @include mq($until: 'desktop') {
      width: 45px;
    }
  }

  .headmap-text,
  .headnews-text,
  .headlink-text {
    color: var(--amc-black);
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 1px;
    min-width: 100px;
    max-width: 145px;
    line-height: 1.3;

    @include mq($until: 'desktop') {
      font-size: 12px;
    }
  }

  &:hover {
    text-decoration: none;
    color: var(--amc-menuActive);

    .headmap-text {
      //color: var(--amc-brand1);
    }
  }

  &.headnews {
    color: var(--amc-menuActive);

    &:hover {
      color: var(--amc-menu);
    }

    .headnews-text {
      width: 80px;
    }
  }

  &.headmap {
    color: var(--amc-menuActive);

    &:hover {
      color: var(--amc-menu);
    }
  }

  &.headlink {
    text-transform: uppercase;
  }

  &.headlink-voucher,
  &.headlink-shopping {
    text-transform: uppercase;
    color: var(--amc-tag-dress);

    &:hover {
      color: var(--amc-menuActive);
    }
  }

  &.headlink-events,
  &.headlink-food {
    text-transform: uppercase;
    color: var(--amc-tag-food);

    &:hover {
      color: var(--amc-menuActive);
    }
  }

  &.headlink-newsletter {
    color: var(--amc-menuActive);

    &:hover {
      color: var(--amc-menu);
    }

    @include mq($until: 'tabletoffset') {
      display: none;
    }
  }
}
