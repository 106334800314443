a {
  color: var(--amc-link);
  text-decoration: none;

  &:hover {
    color: var(--amc-hover);
    text-decoration: underline;
  }
}

.text a {
  color: var(--amc-menu);
  text-decoration: underline;

  &:active,
  &:hover {
    color: var(--amc-menuActive);
  }
}
