body[data-contenttype="listing"] {

  .listing-grid {
    display: grid;
    grid-template-columns: minmax(0,765px) 510px;
    gap: 12.3rem;

    @include mq($until: desktop) {
      gap: 4.5rem;
    }

    @include mq($until: tabletoffset) {
          grid-template-columns: 1fr;
          gap: 0;
    }

    .main-content-listing-item {
      .container-fluid {
        padding: 0;

        .listing-left-aligned {
          width: 100%;
        }
      }
    }

    .sidebar-listing-item {
      position: relative;
    }
  }

  .content-fluid {

    &.fluid-content {

      .container-fluid {
        .col {
          padding: 0;

          .col-12 {
            padding: 0;

            .listing-block {

              .w-100 {
                max-width: 100%;
              }
            }

            .row {
              flex-direction: column;
              padding: 0;

              .col-md-6 {
                max-width: 100%;

                .content-image {
                  img {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.listing-base-info {
  margin-bottom: 68px;

  @include mq($until: tabletoffset) {
    margin-bottom: 0;
  }

  &.content-block {

    @include mq($until: tabletoffset) {
      margin-bottom: 0;
    }
  }
}

.listing-name {
  margin-top: 38px;
  margin-bottom: 38px;
}

.listing-container {
  display: grid;
  grid-template-columns: auto 510px;
  width: 100%;

  .listing-grid-col {
    position: relative;
  }

  @include mq($until: tabletoffset) {
    grid-template-columns: 1fr;
  }

  @include mq($until: phablet) {
    grid-template-columns: 1fr;
  }
}

.listing-slider {
  position: relative;
  max-height: 706px;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  //height: 70vh;
  //overflow: hidden;
}

.listing-slider-slide {
  width: 100%;
  //height: 70vh;
  //max-height: 650px;
  height: 100%;
  //padding-bottom: 60%;
  max-height: $grid-width * 10;
  overflow: hidden;
  //padding-bottom: calc(#{$grid-width-unitless * 10} / 1200 * 100%);
}

.listing-slider-slide-img {
  //width: 75%;
  //margin-bottom: $grid-width;
  display: block;

  //@include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
  //  width: 100%;
  //  margin-bottom: 0;
  //}
}

.listing-slider-slide-img {
  //height: 700px;
  object-fit: cover;

  @include mq($until: tabletoffset) {
    height: 640px;
  }

  @include mq($until: phablet) {
    height: 350px;
  }
}

.listing-slider-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  height: $grid-width;

  .icon {
    position: absolute;
    height: 24px;
    display: block;
    fill: var(--amc-white);
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.listing-details {
  @include fluid-type(padding, map-get($mq-breakpoints, 'tabletoffset'), map-get($mq-breakpoints, 'desktop'), 20px, 40px);

  position: absolute;
  right: 0;
  top: 0;
  background: var(--amc-white);
  border: 2px solid var(--amc-border);
  z-index: 11;
  box-sizing: border-box;
  min-height: 100%;
  max-width: 510px;
  width: 100%;

  @include mq($until: tabletoffset) {
    max-width: none;
    min-height: auto;
  }

  @include mq($until: tabletoffset) {
    margin-bottom: 48px;
  }

  &.has-multiple-places {
    min-height: $grid-width * 10;

    @include mq($until: tabletoffset) {
      min-height: auto;
    }
  }

  &.has-retailer-info {
    @include fluid-type(padding-bottom, map-get($mq-breakpoints, 'tabletoffset'), map-get($mq-breakpoints, 'desktop'), 84px, 114px);

    .am-listing-places-info {
      margin-bottom: $baseline;
    }

    .place-acceptance-info {
      bottom: $baseline * 2;

      @include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
        bottom: $baseline * 3;
      }
    }
  }

  @include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
    width: 100%;
    position: relative;
    right: auto;
    bottom: auto;
  }
}

.listing-category {
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  display: block;
  text-transform: uppercase;
  color: var(--amc-brand4);

  @include font-bold;
}

.listing-name {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 40px, 60px);

  line-height: 1.2;

  @include font-heavy;
}

.listing-logo {

  position: relative;
  max-width: $grid-width * 3;
  max-height: $grid-width * 2;
  margin-bottom: 30px;

  img {
    //width: 100%;
    display: block;
    max-height: $grid-width * 2;

    @include mq($until: tabletoffset) {
      max-height: $grid-width * 1.5;
    }
  }

  @include mq($until: phablet) {
    position: static;
  }
}

.inputgroup-listing-places-select {
  position: relative;
  margin-bottom: $grid-width * 0.25;
}

.listing-places-select {
  width: 100%;
  height: 42px;
  border: 2px solid var(--amc-borders);
  border-radius: 0;
  appearance: none;
  font-size: 18px;
  font-weight: 700;
  color: var(--amc-text);
  position: relative;
  padding: 5px $grid-width + $grid-width * 0.25 5px 16px;
  background-color: var(--amc-white);

  @include mq($until: tabletoffset) {
    font-size: 16px;
  }
}

.listing-places-select-arrow {
  position: absolute;
  width: 42px;
  height: 42px;
  right: 0;
  top: 0;
  border-left: 2px solid var(--amc-borders);
  pointer-events: none;

  .icon {
    width: 21px;
    transform: translate(-50%, -50%);
    position: absolute;
    fill: var(--amc-brand1);
    top: 50%;
    left: 50%;
  }
}

.listing-tabs {
  display: flex;
  justify-content: space-between;
  position: relative;

  &:after {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    border-bottom: 2px solid var(--amc-text-disabled);
    content: '';
  }

  @include mq($until: phablet) {
    display: block;
  }
}

.listing-tab {
  @include font-heavy;

  position: relative;
  font-size: 20px;
  padding: 10px 0;
  text-transform: uppercase;
  color: var(--amc-text-disabled);
  display: inline-block;
  //float: left;
  cursor: pointer;
  //width: 48%;
  //margin-right: 4%;
  //width: 50%;
  //flex-basis: 50%;

  //&:last-child {
  //  margin-right: 0;
  //}

  &:after {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    border-bottom: 2px solid var(--amc-text-disabled);
    content: '';
  }

  &:hover,
  &.is-active {
    color: var(--amc-focus);

    &:after {
      border-color: var(--amc-focus);
      z-index: 10;
    }
  }

  @include mq($until: phablet) {
    width: 100%;
    margin: 0;
  }
}

.listing-tab-content {
  @include font-medium;

  font-size: 18px;
  line-height: 120%;
  display: block;
  padding-bottom: $grid-width * 0.25;

  //.row {
  //  padding: 30px 0 10px;
  //}

  .open-hours-title {
    font-size: 18px;
    font-weight: 900;
    line-height: 1.2;
    text-transform: capitalize;
    margin-bottom: 8px;
  }

  .listing-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    font-style: normal;
    width: 100%;

    &.address {
      gap: 14px;
      margin-bottom: 16px;
    }

    .day {


      .weekday-block {
        width: 110px;
      }
    }
  }

  .listing-info-row {
    display: flex;
    align-items: center;
    gap: 12px;

    &.open_hours {
      margin-bottom: 0;

      &.hasVoucherLink {
        margin-bottom: 20px;

        @include mq($until: tabletoffset) {
          margin-bottom: 0;
        }


      }
    }

    &.address {
      a {
        margin-left: auto;
      }
    }

    &.socials {
      gap: 8px;

      .icon {
        display: flex;
        align-items: center;
      }
    }

    a {
      color: #000;
    }

    .icon-col {
      padding: 0;
      width: $grid-width;
      //float: left;
      //margin-right: $grid-width;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;


      &.background {
        background-color: var(--amc-brand1);
        border-radius: 50%;
        fill: #fff;
        width: 40px;
        height: 40px;

        svg {
          height: 20px;
          width: auto;
        }
      }

      &.margin-left {
        margin-left: auto;
      }
    }

    .text-col {
      font-weight: bold;
      display: inline-block;
      margin-left: $grid-width * 0.25;
    }

    .listing-info-col {
      width: 48%;
      margin-right: 4%;

      &:last-child {
        margin-right: 0;
      }

      @include mq($until: phablet) {
        width: 100%;
        margin: 0;
      }
    }
  }

  //svg {
  //  margin-top: 4px;
  //  width: 40px;
  //  height: 40px;
  //  display: block;
  //  float: left;
  //  color: var(--amc-brand4);
  //}
  //
  //[class*='row'] {
  //  //padding: $grid-width / 4;
  //  margin-right: -$grid-width / 4;
  //  margin-left: -$grid-width / 4;
  //}
  //
  //[class*='col'] {
  //  padding-right: $grid-width / 4;
  //  padding-left: $grid-width / 4;
  //}
}

.listing-select {
  font-size: 15px;
  padding: 10px 20px;
  letter-spacing: 1px;
  width: 100%;
  display: block;
  margin-bottom: $grid-width * 0.5;
  border: 2px solid var(--amc-border);

  @include font-heavy;
}

.listing-places-info {
  &.has-more-spacing {
    padding-bottom: $baseline;
    margin-bottom: 0;

    @include mq($until: tabletoffset) {
      margin-bottom: $baseline;
    }

    @include mq($until: phablet) {
      padding-bottom: $baseline * 2;
      margin-bottom: 0;

    }
  }
}

.place-acceptance-info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  color: var(--amc-brand1);
  background: var(--amc-white);
  padding: $grid-width * 0.125 50px;
  align-items: center;
  border-top: 2px solid var(--amc-border);

  @include fluid-type(padding-left padding-right, map-get($mq-breakpoints, 'tabletoffset'), map-get($mq-breakpoints, 'desktop'), 20px, 34px);

  @include mq($until: phablet) {
    &.open_hours {
      flex-direction: column;
    }
  }

  a {
    color: var(--amc-white);
    text-decoration: underline;
  }

  .icon-col {
    padding: 0;
    width: $grid-width;
    //float: left;
    //margin-right: $grid-width;
  }

  .icon {
    max-width: $grid-width * 0.25 * 3;
    max-height: $grid-width * 0.25 * 3;
    fill: var(--amc-black);
  }

  .text-col {
    font-weight: bold;
    display: inline-block;
    margin-left: $grid-width * 0.25;
  }

  .listing-info-col {
    width: 48%;
    margin-right: 4%;

    &:last-child {
      margin-right: 0;
    }

    @include mq($until: phablet) {
      width: 100%;
      margin: 0;
    }
  }

  & + .listing-retailer-info {
    transform: translateY(-100%);

    @include mq($until: phablet) {
      transform: translateY(100%);
      outline: 2px solid var(--amc-border);
    }
  }
}

.listing-retailer-info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  color: var(--amc-white);
  background: var(--amc-brand5);
  padding: $grid-width * 0.125 50px;
  align-items: center;

  @include fluid-type(padding-left padding-right, map-get($mq-breakpoints, 'tabletoffset'), map-get($mq-breakpoints, 'desktop'), 20px, 50px);

  a {
    color: var(--amc-white);
    text-decoration: underline;
  }

  .icon-col {
    padding: 0;
    width: $grid-width;
    //float: left;
    //margin-right: $grid-width;
  }

  .icon {
    max-width: $grid-width * 0.25 * 3;
    max-height: $grid-width * 0.25 * 3;
    fill: var(--amc-white);
  }

  .text-col {
    font-weight: bold;
    display: inline-block;
    margin-left: $grid-width * 0.25;
  }

  .listing-info-col {
    width: 48%;
    margin-right: 4%;

    &:last-child {
      margin-right: 0;
    }

    @include mq($until: phablet) {
      width: 100%;
      margin: 0;
    }
  }
}

.listing-back-link-container {
  display: inline-flex;
  color: var(--amc-text);
  text-decoration: none;

  .btn-secondary {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .icon-col {
    width: $grid-width;

    .icon {
      width: 33%;
      margin-top: 50%;
      fill: var(--amc-text);

    }
  }

  &:hover {
    color: var(--amc-link);
    text-decoration: none;

    .detail-page-backlink {
      color: var(--amc-link);
    }

    .icon {
      fill: var(--amc-link);
    }
  }
}

.day-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .weekday-block {
    display: block;
    width: 120px;
    font-weight: 600;
  }

}

.open-hours-quote {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  opacity: .5;
}

.listing-description {
  position: relative;
  margin-bottom: 98px;

  &.listing-grid {

    @include mq($until: desktop) {
      gap: 0;
    }
    @include mq($until: tabletoffset) {
      .listing-desc-content {
        order: 2;
      }
    }

    .listing-desc-back {
      display: flex;
      align-items: flex-end;

      @include mq($until: tabletoffset) {
        margin-bottom: 4rem;
      }
    }
  }

  @include mq($until: tabletoffset) {
    top: auto;
    width: 100%;
    margin-bottom: 70px;
  }

  .content-block {
    margin-bottom: 20px;
  }

  .listing-desc-headline {
    .text__headline {
      font-size: 32px;
    }
  }
}

.listing-left-aligned {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 52%;
  margin-bottom: 70px;

  @include mq($until: tabletoffset) {
    margin-right: 0;
    width: 100%;
  }

  &:last-child {
    margin-bottom: 98px;
  }

  .content-image-slideshow {
    margin: 0;
  }
}

.listing-tab-container {
  display: flex;
  flex-direction: column;

  @include mq($until: tabletoffset) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
  }

  @include mq($until: phablet) {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

}

.listing-map {
  height: 515px;

  @include mq($until: tabletoffset) {
    width: 100%;
  }
}

.day {
  width: 100%;
  line-height: 1.5;
}

.day-break-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.img-copyright {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 10px;
  background-color: #DEE4E3;
  color: rgba(0,0,0,.3);
  padding: 5px 15px;
}


