body:after {
  display: none;
  content: "ultrawide";

  @include mq(mobile, phablet) {
    content: "mobile";
  }

  @include mq(phablet, tablet) {
    content: "phablet";
  }

  @include mq(tablet, tabletoffset) {
    content: "tablet";
  }

  @include mq(tabletoffset, smalldesktop) {
    content: "tabletoffset";
  }

  @include mq(smalldesktop, desktop) {
    content: "smalldesktop";
  }

  @include mq(desktop, widedesktop) {
    content: "desktop";
  }

  @include mq(widedesktop, wide) {
    content: "widedesktop";
  }

  @include mq(wide, ultrawide) {
    content: "wide";
  }
}
