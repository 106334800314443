html {
  box-sizing: border-box;
  line-height: 1.6;
  color: var(--amc-text);
  background-color: var(--amc-white);
  overflow-y: scroll;

  @include font-medium;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 16px, 18px);
  @include font-medium;

  //overflow: hidden; // removed to make sticky nav work
  background-color: var(--amc-white);
  margin: 0;
}

::-moz-selection {
  color: var(--amc-white);
  background: var(--amc-brand1);
  text-shadow: none;
}

::selection {
  color: var(--amc-white);
  background: var(--amc-brand1);
  text-shadow: none;
}

:focus {
  outline: 0;
}

textarea {
  resize: vertical;
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  max-height: 100%;
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;

  @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 25px, 50px);

  padding-top: 10px;
}

figcaption {
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
  color: #999;
}
