.maphead {
  border: 2px solid var(--amc-border);
  border-left: none;
  overflow: hidden;
  display: flex;

  //@include mq($until: 'tabletoffset') {
  //  border-left: 2px solid var(--amc-border);
  //  //border-bottom: none;
  //}

  @include mq($until: 'tabletoffset') {
    display: block;
    border-left: 2px solid var(--amc-border);

    .maphead-categories {
      height: 0;
      overflow: hidden;
      flex-direction: column;
    }

    .map-legend-toggle {
      display: inline-block;
    }

    .maphead-button {
      &:last-child {
        border-bottom: none;
      }
    }

    &.is-legend-open {
      .maphead-categories {
        height: auto;
      }

      .map-legend-toggle {
        .icon {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }
  }

  .maphead-categories {
    display: flex;
    flex-basis: initial;
    width: 100%;
    justify-content: flex-end;
  }
}

.map-legend-toggle {
  position: absolute;
  width: $grid-width;
  height: $grid-width;
  color: var(--amc-text);
  //background-color: var(--amc-landingPageMenu);
  display: none;
  right: 0;
  top: 0;
  cursor: pointer;

  .icon {
    height: 24px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: currentColor;
  }

  &:hover {
    background-color: var(--amc-landingPageMenuActive);
  }
}

.maphead-button {
  @include font-heavy;

  font-size: 13px;
  letter-spacing: 1px;
  width: 145px;
  height: 145px;
  padding: 10px;
  float: left;
  text-align: center;
  color: var(--amc-text-disabled);
  text-transform: uppercase;
  border-left: 2px solid var(--amc-border);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  .icon {
    fill: var(--amc-text-disabled);
  }

  @include mq($until: map-get($mq-breakpoints, 'desktop')) {
    width: $grid-width * 2;
    height: $grid-width * 2;
  }

  @include mq($until: map-get($mq-breakpoints, 'tablet')) {
    width: 100%;
    height: $grid-width;
    flex-direction: row;
    justify-content: flex-start;
    border-left: none;
    border-bottom: 2px solid var(--amc-border);
  }

  &:hover,
  &.is-active {
    color: var(--amc-focus);

    .icon {
      fill: var(--amc-focus);
    }

    &.tag-culture {
      color: var(--amc-tag-culture);

      .icon {
        fill: var(--amc-tag-culture);
      }
    }

    &.tag-default {
      color: var(--amc-tag-default);

      .icon {
        fill: var(--amc-tag-default);
      }
    }

    &.tag-dress {
      color: var(--amc-tag-dress);

      .icon {
        fill: var(--amc-tag-dress);
      }
    }

    &.tag-family {
      color: var(--amc-tag-family);

      .icon {
        fill: var(--amc-tag-family);
      }
    }

    &.tag-food {
      color: var(--amc-tag-food);

      .icon {
        fill: var(--amc-tag-food);
      }
    }

    &.tag-townhall {
      color: var(--amc-tag-townhall);

      .icon {
        fill: var(--amc-tag-townhall);
      }
    }

    &.tag-parking {
      color: var(--amc-tag-parking);

      .icon {
        fill: var(--amc-tag-parking);
      }
    }
  }
}

.maphead-button-icon {
  width: auto;
  max-width: 54px;
  height: 100%;
  max-height: 54px;
  margin-right: auto;
  margin-left: auto;
  display: block;
  margin-bottom: 10px;

  @include mq($until: map-get($mq-breakpoints, 'tablet')) {
    margin: 0 15px 0 0;
    max-width: 36px;
    max-height: 36px;
    margin-bottom: 0;
  }
}

.maphead-button-text {
  display: block;
  line-height: 1.2;
  min-height: 2.4em; // two lines
  margin-top: $grid-width * 0.125;
}

.maphead-button--gps {
  &.gpsactive {
    color: var(--amc-brand1);
  }
}

.maphead-text {
  @include font-heavy;

  font-size: 13px;
  letter-spacing: 1px;
  //width: 145px;
  //height: 145px;
  padding: 10px;
  float: left;
  text-align: center;
  color: var(--amc-borders);
  text-transform: uppercase;
  border-left: 2px solid var(--amc-border);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.maphead-text--filter {
  font-size: 18px;
  letter-spacing: 1.3px;
  text-align: left;
  color: var(--amc-text);
  width: auto;
  padding: $grid-width * 0.5;
  cursor: default;
  padding-left: $grid-width;

  @include mq($until: map-get($mq-breakpoints, 'tablet')) {
    width: 100%;
    padding: $grid-width * 0.25;
    line-height: $grid-width * 0.5;
    display: block;
    border: none;
    text-align: left;
    font-size: $grid-width * 0.25;
  }

  @include mq($until: 350px) {
    font-size: 14px;
  }
}

.maphead-button-categories {
  float: right;
}

.map {
  //width: 100%;
  position: relative;
  height: 75vh;
  max-height: 850px;
  width: calc(100% - 450px);
  left: 450px;

  @include mq($until: 'desktop') {
    width: calc(100% - 350px);
    left: 350px;
  }

  @include mq($until: 'tabletoffset') {
    width: 100%;
    left: 0;
  }
}

.map-container--embed {
  .map {
    width: 100%;
    left: 0;
  }
}

.map-container {
  position: relative;

  &.no-map-list {
    .map {
      width: 100%;
      left: 0;
    }
  }
}

.maplist {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 450px;
  background: var(--amc-white);
  z-index: 1;
  overflow-y: auto;

  @include mq($until: 'desktop') {
    max-width: 350px;
    //position: relative;
    //top: auto;
    //bottom: auto;
    //left: auto;
    //overflow-y: initial;
    //margin: 40px 0;
  }

  @include mq($until: 'tabletoffset') {
    display: none;
  }
}

.maplist-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.maplist-item {
  margin-bottom: 2px;
  cursor: pointer;
  //display: flex;
  //flex-flow: row nowrap;
  display: block;

  &:hover {
    color: var(--amc-brand4);
    background-color: var(--amc-hover-background);
    text-decoration: none;

    .maplist-title {
      color: var(--amc-hover);
    }
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.maplist-image {
  display: block;
  float: left;
  max-width: $grid-width * 2;
}

.maplist-content {
  padding: 15px 30px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mq($until: map-get($mq-breakpoints, 'desktop')) {
    padding: 15px;
  }
}

.maplist-title {
  @include font-heavy;

  font-size: 15px;
  margin-bottom: 5px;
  color: var(--amc-text);
}

.maplist-text {
  font-size: 17px;
  margin: 0;
  color: var(--amc-grey);

  @include font-bold;
}

.mapinfo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 550px;
  padding: 30px 30px 30px 0;
  background: var(--amc-white);
  z-index: 1;
  overflow-y: auto;

  @include mq($until: map-get($mq-breakpoints, 'desktop')) {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    overflow-y: initial;
    margin: 40px 0;
    padding: 0;
  }
}

.mapinfo-back {
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
  margin-bottom: 30px;

  @include font-heavy;

  svg {
    width: 32px;
    height: 32px;
    display: inline-block;
  }

  span {
    display: inline-block;
    margin-left: 15px;
  }
}

.mapinfo-title {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 30px, 50px);
  @include font-heavy;
}

.mapboxgl-popup {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 16px, 18px);
  @include font-medium;

  line-height: 1.6;
  color: var(--amc-text);
  margin: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;

  .mapboxgl-popup-tip {
    display: none;
  }

  @include mq($until: tablet) {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: none !important;
    z-index: 10;

    &.mapboxgl-popup-anchor-top,
    &.mapboxgl-popup-anchor-right,
    &.mapboxgl-popup-anchor-bottom,
    &.mapboxgl-popup-anchor-left {
      padding: 0;

      .mapboxgl-popup-content {
        &:after, &:before {
          top: auto;
          bottom: 100%;
          left: 50%;
        }

        &:before {
          border-color: transparent;
          border-bottom-color: var(--amc-borders);
          border-width: 18px;
          margin-left: -18px;
        }

        &:after {
          border-color: transparent;
          border-bottom-color: var(--amc-white);
          border-width: 15px;
          margin-left: -15px;
        }
      }
    }
  }
}

.mapboxgl-popup-content {
  border: 2px solid var(--amc-borders);
  box-shadow: none;
}

.mapboxgl-popup-content:after, .mapboxgl-popup-content:before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.mapboxgl-popup-anchor-top {
  .mapboxgl-popup-content {
    &:after, &:before {
      bottom: 100%;
      left: 50%;
    }

    &:before {
      border-color: transparent;
      border-bottom-color: var(--amc-borders);
      border-width: 18px;
      margin-left: -18px;
    }

    &:after {
      border-color: transparent;
      border-bottom-color: var(--amc-white);
      border-width: 15px;
      margin-left: -15px;
    }
  }
}

.mapboxgl-popup-anchor-right {
  padding-right: 10px;

  .mapboxgl-popup-content {
    &:after, &:before {
      top: 50%;
      left: 100%;
    }

    &:before {
      border-color: transparent;
      border-left-color: var(--amc-borders);
      border-width: 18px;
      margin-top: -18px;
    }

    &:after {
      border-color: transparent;
      border-left-color: var(--amc-white);
      border-width: 15px;
      margin-top: -15px;
    }
  }
}

.mapboxgl-popup-anchor-bottom {
  padding-bottom: 10px;

  .mapboxgl-popup-content {
    &:after, &:before {
      top: 100%;
      left: 50%;
    }

    &:before {
      border-color: transparent;
      border-top-color: var(--amc-borders);
      border-width: 18px;
      margin-left: -18px;
    }

    &:after {
      border-color: transparent;
      border-top-color: var(--amc-white);
      border-width: 15px;
      margin-left: -15px;
    }
  }
}

.mapboxgl-popup-anchor-left {
  padding-left: 10px;

  .mapboxgl-popup-content {
    &:after, &:before {
      right: 100%;
      top: 50%;
    }

    &:before {
      border-color: transparent;
      border-right-color: var(--amc-borders);
      border-width: 18px;
      margin-top: -18px;
    }

    &:after {
      border-color: transparent;
      border-right-color: var(--amc-white);
      border-width: 15px;
      margin-top: -15px;
    }
  }
}

.mapboxgl-popup-close-button {
  background-image: url("/assets/img/close-000.svg");
  background-color: transparent;
  color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0;
  width: $grid-width * 0.5;
  height: $grid-width * 0.5;
  top: $grid-width * 0.25;
  right: $grid-width * 0.25;
}

.mapboxgl-popup-content {
  width: 100%;
  max-width: $grid-width * 7;
  padding: $grid-width * 0.25;

  .popup-headline {
    margin: 0;
    display: block;
    font-weight: 900;
    font-size: 36px;
    margin-bottom: 18px;
    margin-right: $grid-width * 0.5 + $grid-width * 0.25;
  }

  .popup-content {
    margin: 0;
    display: block;
    font-weight: 600;
  }
}

.mapboxgl-container .leaflet-marker-icon {
  cursor: pointer;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 15px;
}

.mapboxgl-marker:not(.mapboxgl-user-location-dot) {
  background-image: url('/assets/img/markers/pins/default.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: $grid-width;
  height: $grid-width;
  //border-radius: 50%;
  cursor: pointer;

  .icon {
    display: none;
    fill: var(--amc-focus);
  }

  &.has-tag-2449 {
    background-image: url('/assets/img/markers/pins/townhall.svg');
  }

  &.has-tag-2450 {
    background-image: url('/assets/img/markers/pins/culture.svg');
  }

  &.has-tag-2451 {
    background-image: url('/assets/img/markers/pins/family.svg');
  }

  &.has-tag-2452 {
    background-image: url('/assets/img/markers/pins/food.svg');
  }

  &.has-tag-2453 {
    background-image: url('/assets/img/markers/pins/dress.svg');
  }

  &.has-tag-2549 {
    background-image: url('/assets/img/markers/pins/parking.svg');
  }
}

.map-container[data-zoom-factor="1"] .mapboxgl-marker,
.map-container[data-zoom-factor="2"] .mapboxgl-marker,
.map-container[data-zoom-factor="3"] .mapboxgl-marker {
  &:not(.mapboxgl-user-location-dot) {
    width: $grid-width * 0.25;
  }
}

.map-container[data-zoom-factor="4"] .mapboxgl-marker,
.map-container[data-zoom-factor="5"] .mapboxgl-marker,
.map-container[data-zoom-factor="6"] .mapboxgl-marker,
.map-container[data-zoom-factor="7"] .mapboxgl-marker,
.map-container[data-zoom-factor="8"] .mapboxgl-marker,
.map-container[data-zoom-factor="9"] .mapboxgl-marker,
.map-container[data-zoom-factor="10"] .mapboxgl-marker,
.map-container[data-zoom-factor="11"] .mapboxgl-marker,
.map-container[data-zoom-factor="12"] .mapboxgl-marker,
.map-container[data-zoom-factor="13"] .mapboxgl-marker,
.map-container[data-zoom-factor="14"] .mapboxgl-marker,
.map-container[data-zoom-factor="15"] .mapboxgl-marker {
  &:not(.mapboxgl-user-location-dot) {
    width: $grid-width * 0.5;
  }
}

.map-container[data-filter-id="2449"] .mapboxgl-marker:not(.mapboxgl-user-location-dot) {
  background-image: url('/assets/img/markers/pins/townhall.svg');
}

.map-container[data-filter-id="2450"] .mapboxgl-marker:not(.mapboxgl-user-location-dot) {
  background-image: url('/assets/img/markers/pins/culture.svg');
}

.map-container[data-filter-id="2451"] .mapboxgl-marker:not(.mapboxgl-user-location-dot) {
  background-image: url('/assets/img/markers/pins/family.svg');
}

.map-container[data-filter-id="2452"] .mapboxgl-marker:not(.mapboxgl-user-location-dot) {
  background-image: url('/assets/img/markers/pins/food.svg');
}

.map-container[data-filter-id="2453"] .mapboxgl-marker:not(.mapboxgl-user-location-dot) {
  background-image: url('/assets/img/markers/pins/dress.svg');
}

.map-container[data-filter-id="2549"] .mapboxgl-marker:not(.mapboxgl-user-location-dot) {
  background-image: url('/assets/img/markers/pins/parking.svg');
}

//.mapboxgl-popup {
//  max-width: 200px;
//}
//
//.mapboxgl-popup-content {
//  text-align: center;
//  font-family: 'Open Sans', sans-serif;
//}
