@use "sass:math";

$time-unit: $grid-width;

.manual-schedule {
  .days {
    padding: 0;
    border: 2px solid var(--amc-landingPageBorders);
    border-bottom: none;
    text-align: right;
    display: flex;

    .days-nav-prev {
      width: $grid-width;
      cursor: pointer;
      border: none;
      border-right: 2px solid var(--amc-landingPageBorders);
      background-color: var(--amc-white);

      //@include mq($from: 'mobile', $until: 'tablet') {
      //  width: $grid-width / 2;
      //}

      .icon {
        width: $size-chevrons;
      }

      &:hover {
        .icon {
          fill: var(--amc-brand1);
        }
      }
    }

    .days-nav-next {
      width: $grid-width;
      cursor: pointer;
      border: none;
      border-left: 2px solid var(--amc-landingPageBorders);
      background-color: var(--amc-white);

      //@include mq($from: 'mobile', $until: 'tablet') {
      //  width: $grid-width / 2;
      //}

      .icon {
        width: $size-chevrons;
      }

      &:hover {
        .icon {
          fill: var(--amc-brand1);
        }
      }
    }

    .days-list {
      //max-width: $grid-width * 2 * 7;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      width: calc(100% - #{$grid-width * 2});
      height: $grid-width * 2;

      @include mq($from: 'mobile', $until: 'phablet') {
        white-space: normal;
        height: $grid-width * 3.5;
      }

      @include mq($from: 'phablet', $until: 'tablet') {
        width: calc(100% - #{$grid-width});
      }
    }

    .days-holder {
      position: absolute;
      width: 100%;
    }

    .inputgroup {
      display: inline-block;
      height: $grid-width * 2;
      border-left: 2px solid var(--amc-landingPageBorders);
      width: 100%;
      text-align: center;
      vertical-align: middle;
      position: relative;
      max-width: calc(100% / 7);

      &.is-disabled {
        color: var(--amc-landingPageBorders);
      }

      @include mq($until: tablet) {
        &:first-child {
          border-left: none;
        }
      }

      @include mq($from: 'mobile', $until: 'phablet') {
        height: $grid-width * 0.5;
        max-width: 100%;
        margin: 0;
        border-bottom: 2px solid var(--amc-landingPageBorders);
        border-left: none;
      }

      .day-input {
        display: none;

        &:hover + .day-label,
        &:checked + .day-label {
          color: var(--amc-focus);
        }
      }

      .day-label {
        vertical-align: middle;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;

        @include mq($from: 'mobile', $until: 'phablet') {
          display: flex;
        }

        .day-name {
          font-weight: 900;
        }

        .day {
          font-size: 3em;
          font-weight: 900;
          line-height: 1em;
          display: block;

          @include mq($from: 'mobile', $until: 'phablet') {
            margin-right: $grid-width * 0.125;
            font-size: 1.5em;
          }
        }

        .month {
          display: inline-block;
          position: relative;
          font-weight: 900;

          @include mq($from: 'mobile', $until: 'phablet') {
            margin-top: auto;
          }
        }
      }
    }
  }

  .stages, .search {
    padding: $grid-width * 0.5 $grid-width;
    border: 2px solid var(--amc-landingPageBorders);
    border-bottom: none;

    .inputgroup {
      position: relative;
    }

    .stage-select {
      width: 100%;
      height: $grid-width;
      border: 2px solid var(--amc-landingPageBorders);
      border-radius: 0;
      appearance: none;
      padding: $grid-width * 0.25;
      font-size: 18px;
      color: var(--amc-text);
      position: relative;
      padding-right: $grid-width + $grid-width * 0.25;
      font-weight: 900;
    }

    .stage-select-arrow {
      position: absolute;
      width: $grid-width;
      height: $grid-width;
      right: 0;
      top: 0;
      border-left: 2px solid var(--amc-landingPageBorders);
      pointer-events: none;

      .icon {
        width: 24px;
        transform: translate(-50%, -50%);
        position: absolute;
        fill: var(--amc-landingPageBorders);
        top: 50%;
        left: 50%;
      }
    }

    @include mq($until: tablet) {
      padding: 0;
      border: none;
      margin-bottom: $grid-width * 0.25;
    }
  }

  .schedule-events {
    position: relative;
    padding-bottom: $grid-width;
    border-left: 2px solid var(--amc-landingPageBorders);

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      top: 0;
      border-top: 2px solid var(--amc-landingPageBorders);
    }

    @include mq($until: tablet) {
      //margin-left: $grid-width;

      &:before {
        display: none;
      }
    }
  }

  .schedule-event {
    position: relative;
    padding-top: $grid-width * 0.5;
    padding-bottom: $grid-width * 0.5;
    padding-left: $grid-width;
    min-height: $time-unit * 2;
    max-height: $time-unit * 10;

    //@for $i from 1 through 40 {
    //  &.tu-#{$i} {
    //    height: $time-unit * $i;
    //  }
    //}

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: -2px;
      left: 0;
      border-bottom: 2px dashed var(--amc-landingPageBorders);
    }

    .event-time {
      color: var(--amc-text);

      @include font-bold;

      @include mq($until: tablet) {
        display: block;
        padding: math.div($grid-width, 16) $grid-width * 0.25;
        background: var(--amc-landingPageBorders);
        margin-bottom: $grid-width * 0.125;
      }
    }

    .event-starttime {
      position: absolute;
      width: $grid-width;
      left: -$grid-width;
      //top: 0;
      font-size: $grid-width * 0.25;
      color: var(--amc-landingPageBorders);
      top: - $grid-width * 0.25;
      line-height: $grid-width * 0.5;
      height: $grid-width * 0.5;
      text-align: right;
      padding-right: $grid-width * 0.25;

      @include mq($until: tablet) {
        top: 0;
        display: none;
      }
    }

    .event-endtime {
      position: absolute;
      width: $grid-width;
      left: -$grid-width;
      //bottom: 0;
      font-size: $grid-width * 0.25;
      color: var(--amc-landingPageBorders);
      bottom: - $grid-width * 0.25;
      line-height: $grid-width * 0.5;
      height: $grid-width * 0.5;
      text-align: right;
      padding-right: $grid-width * 0.25;
      display: none;

      @include mq($until: tablet) {
        display: none;
      }
    }

    .event-title {
      padding-right: $grid-width * 4;
    }

    .event-location {
      font-weight: 900;
      margin-left: $grid-width * 0.5;
    }

    //.event-subtitle {
    //  display: none;
    //}

    .event-description {
      padding-right: $grid-width * 4;
    }

    .event-links {
      position: absolute;
      right: 0;
      top: $grid-width * 0.5;display: flex;
      flex-direction: column;
      width: max-content;
    }

    //.event-link {
    //  position: absolute;
    //  right: 0;
    //  top: $grid-width / 2;
    //}
    //
    //.event-page-link {
    //  position: absolute;
    //  right: 0;
    //  top: $grid-width;
    //}

    &:last-child {
      .event-endtime {
        display: block;
      }
    }

    @include mq($until: tablet) {
      padding-top: 0;
      padding-left: 0;
      background-color: #fff;

      .event-title,
      .event-subtitle,
      .event-description,
      .event-links,
      .event-location {
        padding: 0;
        margin-left: $grid-width * 0.25;
        margin-right: $grid-width * 0.25;
        display: block;
      }

      .event-title {
        margin-bottom: $grid-width * 0.25;
      }

      .event-subtitle {
        margin-bottom: $grid-width * 0.25;
      }

      .event-description {
        margin-bottom: $grid-width * 0.25;
        //display: none;
      }

      .event-links {
        position: relative;
        top: auto;
        right: auto;
        margin-top: 0;
      }
    }
  }
}

.search-word {
  display: inline-block;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  position: relative;
  max-width: 100%;

  .search-word-label {
    font-size: 18px;
    color: #a08baa;
    font-weight: 900;
  }

  .search-word-input {
    @include font-heavy;

    padding-right: $grid-width;
    width: 100%;
    background: var(--amc-white);
    float: right;
    margin: 0;
    appearance: none;
    border-radius: 0;

    &::placeholder {
      color: var(--amc-text);
      opacity: 1;
    }
  }

  .search-word-button {
    position: absolute;
    font-size: 20px;
    top: 0;
    right: 0;
    width: 64px;
    height: 64px;
    border: 2px solid var(--amc-landingPageBorders);
    content: '';
    text-align: center;
    line-height: 50px;
    display: flex;
    cursor: pointer;
    background: transparent;

    .icon {
      width: $grid-width * 0.5;
      height: $grid-width * 0.5;
      margin: auto;
      display: block;
      stroke: var(--amc-text);
      fill: none;
    }

    &:hover {
      //background: var(--amc-landingPageBorders);

      .icon {
        stroke: var(--amc-brand4);
      }
    }
  }
}

