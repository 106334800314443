.divider-headline {
  color: var(--amc-borders);
  display: flex;
  align-items: baseline;

  .text__headline {
    flex-shrink: 0;
    margin-bottom: 0;
  }

  &:after {
    content: "";
    display: block;
    height: 0;
    border-bottom: 2px solid var(--amc-borders);
    width: 100%;
    margin-left: $grid-width;
  }
}

.divider-line {
  display: block;
  height: 0;
  border: none;
  border-bottom: 2px solid var(--amc-borders);
  width: 100%;
}
