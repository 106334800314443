.contact-info {
  width: 100%;
  background: #fff;
  margin: 0;
  min-height: 300px;
  outline: 2px solid var(--amc-border);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @include mq($until: 'smalldesktop') {
    flex-flow: row wrap;
    width: 100%;
    margin: 0 0 20px !important;
  }

  @include mq($until: 'tablet') {
    flex-flow: row nowrap;
    margin-bottom: 30px;
  }

  @include mq($until: 'phablet') {
    flex-direction: column;
  }
}

.contact-info-image {
  position: relative;
  height: 100%;
  flex: 0 1 300px;
  background-position: center center;
  background-size: cover;
  //flex: 1;

  @include mq($until: 'smalldesktop') {
    flex-grow: 1;
  }

  @include mq($until: 'tablet') {
    flex-grow: 0;
    flex-shrink: 1;
  }

  .image-image {
    display: block;
  }
}

@supports (object-fit: cover) {
  .contact-info-image {
    height: auto;
    display: flex;
    align-self: stretch;
    overflow: hidden;

    .image-image {
      object-fit: cover;
    }
  }
}


.contact-info-content {
  @include fluid-type(padding-top padding-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 30px);
  @include fluid-type(padding-right padding-left, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 50px);

  flex: 1;

  @include mq($until: 'tablet') {
    order: 2;
  }

  @include mq($until: 'phablet') {
    width: 100%;
  }
}

//.contact-info-title {
//  color: var(--amc-text);
//}
