@import "../vendor/autocomplete-theme-classic";

:root {
  --aa-panel-max-height: none;
}

.site-search-container {
  display: none;
  position: relative;

  &.is-visible {
    display: block !important;
  }
}

.site-search-inner {
  background: var(--amc-white);
  border: 2px solid var(--amc-borders);
  position: absolute;
  z-index: 1000;
  width: calc(100% - #{$grid-width});
  max-height: 80vh;
  overflow: auto;

  @include mq($until: tablet) {
    width: calc(100% - #{$grid-width * 0.5});
    max-height: calc(100vh - 80px); // 80px is the height of the header
    overflow-y: auto;
  }
}

//.site-search-form {
//  position: relative;
//  height: $grid-width * 2;
//  display: flex;
//  align-items: center;
//  border-bottom: 2px solid var(--amc-borders);
//  @include fluid-type(padding-left padding-right, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 16px, $grid-width / 2);
//
//  .site-search-label {
//    flex-grow: 1;
//    height: 100%;
//  }
//
//  .site-search-input {
//    @include font-heavy;
//    font-size: 36px;
//    height: 100%;
//    display: block;
//    width: 100%;
//    border: none;
//  }
//
//  .site-search-submit-button {
//    //position: absolute;
//  }
//}
//
//.site-search-results-container {
//  @include fluid-type(padding-left padding-right, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 16px, $grid-width / 2);
//  @include fluid-type(padding-top padding-bottom, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 16px, $grid-width / 4);
//
//  position: relative;
//}
//
//.site-search-result {
//  display: block;
//  line-height: $grid-width;
//  border-bottom: 2px solid var(--amc-borders);
//  text-decoration: none;
//  color: var(--amc-text);
//  padding-left: 0;
//  transition: all $transition-time $transition-fx;
//
//  &:hover {
//    background-color: var(--amc-menuActive);
//    color: var(--amc-text);
//    text-decoration: none;
//    padding-left: $baseline / 2;
//  }
//
//  .result-title {
//    display: inline-block;
//    @include font-heavy;
//    margin-right: $baseline / 2;
//  }
//
//  .result-category {
//    color: var(--amc-text-disabled);
//    font-size: 16px;
//  }
//}

.aa-Autocomplete {

  .aa-Form {
    height: $grid-width * 2;
    border: none;
    @include fluid-type(padding-left padding-right, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 16px, $grid-width * 0.5);
  }

  .aa-InputWrapperPrefix {
    display: none;
  }

  .aa-Input {
    @include font-heavy;
    font-size: 36px;
    height: 100%;
    display: block;
    width: 100%;
    border: none;
  }
}

.site-search-results-container {
  @include fluid-type(padding-left padding-right, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 16px, $grid-width * 0.5);
  @include fluid-type(padding-top padding-bottom, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 16px, $grid-width * 0.25);

  position: relative;
}

#autocomplete-results {
  .aa-Panel {
    position: static;
    margin-top: 0;
    border-radius: 0;
    box-shadow: none;
  }


  .aa-PanelLayout,
  .aa-Panel--scrollable {
    padding: 0;
  }

  .aa-Item {
    padding: 0;

    .site-search-result {
      display: block;
      line-height: $grid-width;
      border-bottom: 2px solid var(--amc-borders);
      text-decoration: none;
      color: var(--amc-text);
      padding-left: 0;
      transition: all $transition-time $transition-fx;

      //&[aria-selected='true'],
      //&:hover {
      //  background-color: var(--amc-menuActive);
      //  color: var(--amc-text);
      //  text-decoration: none;
      //  padding-left: $baseline / 2;
      //}

      .result-title {
        display: inline-block;
        @include font-heavy;
        margin-right: $baseline * 0.5;
      }

      .result-category {
        color: var(--amc-text-disabled);
        font-size: 16px;
      }
    }

    &[aria-selected='true'],
    &:hover {
      //back
      .site-search-result {
        background-color: var(--amc-menuActive);
        color: var(--amc-text);
        text-decoration: none;
        padding-left: $baseline * 0.5;
      }

      //background-color: var(--amc-menuActive);
      //color: var(--amc-text);
      //background-color: var(--amc-menuActive);
      //color: var(--amc-text);
      //text-decoration: none;
      //padding-left: $baseline / 2;
    }
  }



  .aa-GradientBottom {
    display: none;
  }

  .aa-SourceNoResults {
    display: none;
  }
}
