.site-navigation {
  display: none;

  &.is-visible {
    display: block !important;
  }
}

.main-navigation {
  @include fluid-type(padding-left padding-right, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 16px, $grid-width);
  @include fluid-type(padding-top padding-bottom, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 16px, $grid-width * 0.5);

  background: var(--amc-brand1);
  position: absolute;
  z-index: 1000;
  width: calc(100% - #{$grid-width});
  max-height: 80vh;
  overflow: auto;

  @include mq($until: tablet) {
    width: calc(100% - #{$grid-width * 0.5});
    max-height: calc(100vh - 80px); // 80px is the height of the header
    overflow-y: auto;
    //padding-bottom: $grid-width / 4;

    .search-form {
      margin-bottom: $grid-width * 0.25; // to make it space out the navigation
    }
  }
}
