.home-teaser-slider {
  //max-height: $grid-width * 10;
  overflow: hidden;

  @include mq($until: 'tabletoffset') {
    padding-bottom: $grid-width;
  }
  //
  //.flickity-viewport {
  //  z-index: 10;
  //}

  .flickity-button {
    position: absolute;
    top: auto;
    bottom: $grid-width * 0.5;
    width: $grid-width !important;
    height: $grid-width !important;
    color: var(--amc-white);
    background: var(--amc-brand1);
    border-radius: 0;

    @include mq($until: 'tabletoffset') {
      bottom: - $grid-width * 0.5;
      width: calc(100% - #{$grid-width * 2});
    }

    &.previous {
      left: 0;
    }

    &.next {
      left: calc(52% - #{$grid-width});

      @include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
        left: auto;
        right: 0;
      }
    }

    &:hover {
      background-color: var(--amc-menuActive);
    }
  }

  .flickity-button-icon {
    height: 24px;
    display: block;
    fill: var(--amc-white);
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .flickity-button:disabled {
    display: none;
  }

  .flickity-page-dots {
    bottom: $grid-width * 0.5;
    left: 64px;
    width: calc(52% - #{$grid-width * 2});
    transform: translateY(-50px);

    @include mq($until: 'tabletoffset') {
      bottom: - $grid-width * 0.5;
      width: calc(100% - #{$grid-width * 2});
    }

    .dot {
      width: 12px;
      height: 12px;
      margin: 1px 4px;
      background: var(--amc-brand1);
      border: 2px solid transparent;
      opacity: 1;

      &:hover {
        background-color: var(--amc-menuActive);
      }

      &.is-selected {
        width: 14px;
        height: 14px;
        margin: 0 4px;
        background: transparent;
        border-color: var(--amc-brand1);
      }
    }
  }

  &.flky-init {
    .home-teaser-slide {
      display: block;
    }

    .home-teaser-slide-image-container {
      margin-bottom: $grid-width * 2;
      overflow: hidden;

      @include mq($until: 'tabletoffset') {
        margin-bottom: 0;
      }
    }
  }
}

.home-teaser-slide {
  display: block;
  width: 100%;

  &:not(:first-child) {
    display: none;
  }
}

.home-teaser-slide-image-container {
  width: 100%;
  max-height: 650px;
  //height: 0;
  //padding-bottom: 60%;
  max-width: $grid-width * 16;
}

.home-teaser-slide-image {
  display: block;
}

.home-teaser-slide-details {
  @include fluid-type(padding, map-get($mq-breakpoints, 'tabletoffset'), map-get($mq-breakpoints, 'desktop'), 20px, 50px);

  position: absolute;
  right: 0;
  bottom: 0;
  width: 48%;
  background: var(--amc-white);
  border: 2px solid var(--amc-border);
  z-index: 11;
  box-sizing: border-box;

  @include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
    width: 100%;
    position: relative;
    right: auto;
    bottom: auto;
  }
}

.home-teaser-slide-info {
  //padding: 40px 100px 0 0;
}

.home-teaser-slide-overline {
  @include font-bold;

  font-size: 17px;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  display: block;
  //text-transform: uppercase;
  color: var(--amc-brand4);
}

.home-teaser-slide-name-link {
  color: var(--amc-text);

  &:hover {
    color: var(--amc-text);
    text-decoration: none;
  }

  @include mq($until: tablet) {
    .btn-secondary {
      margin-top: 0;
    }
  }
}

.home-teaser-slide-name {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 26px, 40px);
  @include font-bold;
  @include font-heavy;

  text-transform: uppercase;
  line-height: 1.2;
}

.home-teaser-slide-text {
  @include mq($until: tablet) {
    display: none;
  }
}
