.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $grid-gutter-width * 0.25;
  padding-left: $grid-gutter-width * 0.25;

  @media (min-width: $md) {
    padding-right: $grid-gutter-width * 0.5;
    padding-left: $grid-gutter-width * 0.5;
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $grid-gutter-width * 0.25;
  padding-left: $grid-gutter-width * 0.25;

  @media (min-width: $md) {
    padding-right: $grid-gutter-width * 0.5;
    padding-left: $grid-gutter-width * 0.5;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: - $grid-gutter-width * 0.25;
  margin-left: - $grid-gutter-width * 0.25;

  @media (min-width: $md) {
    margin-right: - $grid-gutter-width * 0.5;
    margin-left: - $grid-gutter-width * 0.5;
  }
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
