.teaser-slideshow {
  width: calc(100% + #{$grid-width * 0.5});
  margin: 0;

  @include mq($until: 'smalldesktop') {
    width: calc(100% + #{$grid-width * 0.5});
  }

  @include mq($until: 'phablet') {
    width: 100%;
  }

  .flickity-viewport:after {
    content: "";
    width: $grid-width * 0.5;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--amc-white);

    @include mq($until: 'tablet') {
      display: none;
    }
  }

  .flickity-button {
    position: absolute;
    top: calc(50% - #{$grid-width});
    width: $grid-width;
    height: $grid-width;
    color: var(--amc-white);
    background-color: var(--amc-landingPageMenu);
    border-radius: 0;
    transform: none;
    z-index: 10;

    @include mq($until: 'smalldesktop') {
      display: none;
    }

    &:hover {
      background-color: var(--amc-landingPageMenuActive);
    }

    .flickity-button-icon {
      //width: $grid-width / 2;
      height: 24px;
      //margin: 15px;
      display: block;
      //left: 25%;
      //top: 25%;
      fill: var(--amc-white);
      width: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.previous {
      //left: -70px;
      left: - ($grid-width + $grid-width * 0.5);
    }

    &.next {
      //right: -40px;
      right: - $grid-width;
    }
  }

  .flickity-page-dots {
    display: none;
    right: 15px;
    bottom: - $grid-width * 0.5;

    @include mq($until: 'smalldesktop') {
      display: block;
    }

    @include mq($until: 'phablet') {
      right: 0;
    }

    .dot {
      width: 12px;
      height: 12px;
      margin: 1px 4px;
      background: var(--amc-menu);
      border: 2px solid transparent;
      opacity: 1;

      &.is-selected {
        width: 14px;
        height: 14px;
        margin: 0 4px;
        background: transparent;
        border-color: var(--amc-menu);
      }

      &:hover {
        background-color: var(--amc-landingPageMenuActive);
      }
    }
  }
}

.teaser-slide {
  width: calc(100% / 3 - #{$grid-width * 0.5});
  //margin: 0 30px 0 0;
  margin-right: #{$grid-width * 0.5};

  @include mq($until: 'smalldesktop') {
    width: calc(50% - #{$grid-width * 0.5});
  }

  @include mq($until: 'phablet') {
    width: 100%;
    margin: 0;
  }
}

.slider-container {
  .slider-slide {
    position: relative;

    img {
      display: block;
    }
  }

  .swiper-slide {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255,255,255, 0.6);
      content: '';
    }

    &-active {
      &:after {
        display: none;
      }
    }
  }

  .slider-nav {
    position: relative;
  }

  .slider-button-next,
  .slider-button-prev {
    position: absolute;
    top: auto;
    bottom: 0;
    width: $grid-width;
    height: $grid-width;
    color: var(--amc-white);
    background-color: var(--amc-brand1);

    @include mq($until: map-get($mq-breakpoints, 'phablet')) {
      display: none;
    }

    .icon {
      height: 24px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: currentColor;
    }

    &:hover {
      background-color: var(--amc-brand2);
    }
  }

  .slider-button-next {
    right: 0;
  }

  .slider-button-prev {
    left: 0;
  }
}

.slider-pagination {
  bottom: 20px !important;

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 1px 4px !important;
    background: var(--amc-brand1);
    border: 2px solid transparent;
    opacity: 1;

    &-active {
      width: 14px;
      height: 14px;
      margin: 0 4px !important;
      background: transparent;
      border-color: var(--amc-brand1);
    }
  }
}

.teaserslider-image-link {
  display: block;
  border-width: 2px;
  border-style: solid;
  border-color: var(--amc-borders);
  border-bottom: none;

  @include mq($until: map-get($mq-breakpoints, 'phablet')) {
    border-bottom: 2px solid var(--amc-borders);
  }
}

.teaserslider-img {
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.teaserslider-content {
  @include fluid-type(padding, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 20px, 30px);

  border: 2px solid var(--amc-borders);

  @include mq($until: 'phablet') {
    position: relative;
    background: #fff;
    margin: -20px 15px 0;
    z-index: 999;
  }
}

.teaserslider-title {
  color: var(--amc-text);

  @include fluid-type(font-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'wide'), 26px, 34px);
  @include font-heavy;

  @include mq($until: map-get($mq-breakpoints, 'phablet')) {
    margin: 0;
  }
}

.teaserslider-text {
  color: var(--amc-text);

  @include font-medium;

  @include mq($until: map-get($mq-breakpoints, 'phablet')) {
    display: none;
  }
}

.teaserslider-button {
  &-next,
  &-prev {
    width: $grid-width;
    height: $grid-width;
    color: var(--amc-white);
    background-color: var(--amc-menu);
    background-image: none !important;

    @include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
      display: none;
    }

    .icon {
      height: 24px;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: currentColor;
    }

    &:hover {
      background-color: var(--amc-menuActive);
    }
  }

  &-next {
    @include fluid-type(right, map-get($mq-breakpoints, 'wide'), map-get($mq-breakpoints, 'ultrawide'), - ($grid-width * 0.25), -($grid-width * 1.5));
  }

  &-prev {
    @include fluid-type(left, map-get($mq-breakpoints, 'wide'), map-get($mq-breakpoints, 'ultrawide'), - ($grid-width * 0.25), - ($grid-width * 1.5));
  }
}

.teaserslider-pagination {
  position: relative;
  width: 100%;
  margin-top: 15px;
  display: none;

  @include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
    display: block;
  }

  .swiper-pagination-bullet {
    margin: 0 3px;

    &-active {
      background-color: var(--amc-menu);
    }
  }
}

//.content-image-slideshow {
//  width: 100%;
//  margin: auto;
//  margin-right: $grid-gutter-width * 0.5;
//  margin-left: $grid-gutter-width * 0.5;
//
//  @include mq($until: map-get($mq-breakpoints, 'tablet')) {
//    margin-right: $grid-gutter-width * 0.25;
//    margin-left: $grid-gutter-width * 0.25;
//  }
//
//  @include mq($until: map-get($mq-breakpoints, 'phablet')) {
//    margin-right: 0;
//    margin-left: 0;
//  }
//
//  .flickity-viewport {
//    width: 100%;
//  }
//
//  .content-image--slide {
//    width: 100%;
//    position: relative;
//    margin: 0;
//
//    &:not(:first-of-type) {
//      display: none;
//    }
//
//    @include mq($from: 'desktop') {
//      max-width: calc(100% * 2 / 3);
//    }
//  }
//
//  .content-image--slide__image {
//    width: 100%;
//    display: block;
//  }
//
//  &.flickity-enabled {
//    .content-image--slide {
//      height: auto;
//      opacity: 0.6;
//
//      &.is-selected {
//        opacity: 1;
//      }
//
//      &:not(:first-of-type) {
//        display: block;
//      }
//    }
//  }
//
//  .flickity-button {
//    position: absolute;
//    top: auto;
//    bottom: 0;
//    width: $grid-width;
//    height: $grid-width;
//    color: var(--amc-white);
//    background-color: var(--amc-menu);
//    border-radius: 0;
//    transform: none;
//    z-index: 10;
//
//    @include mq($until: 'tabletoffset') {
//      display: none;
//    }
//
//    &:hover {
//      background-color: var(--amc-menuActive);
//    }
//
//    .flickity-button-icon {
//      //width: $grid-width / 2;
//      height: 24px;
//      //margin: 15px;
//      display: block;
//      //left: 25%;
//      //top: 25%;
//      fill: var(--amc-white);
//      width: auto;
//      top: 50%;
//      left: 50%;
//      transform: translate(-50%, -50%);
//    }
//
//    &.previous {
//      left: calc(100% * 1 / 3 / 2);
//
//      @include mq($from: 'tabletoffset', $until: 'desktop') {
//        left: 0;
//      }
//    }
//
//    &.next {
//      right: calc(100% * 1 / 3 / 2);
//
//      @include mq($from: 'tabletoffset', $until: 'desktop') {
//        right: 0;
//      }
//    }
//  }
//
//  .flickity-page-dots {
//    bottom: -32px;
//
//    .dot {
//      width: 12px;
//      height: 12px;
//      margin: 1px 4px;
//      background: var(--amc-menu);
//      border: 2px solid transparent;
//      opacity: 1;
//
//      &.is-selected {
//        width: 14px;
//        height: 14px;
//        margin: 0 4px;
//        background: transparent;
//        border-color: var(--amc-menu);
//      }
//
//      &:hover {
//        background-color: var(--amc-menuActive);
//      }
//    }
//  }
//}

.content-image-slideshow {
  width: 100%;
  padding: 0 32px;

  @include mq($until: desktop) {
    padding: 0 16px;
  }
}

.swiffy-slider {
  max-width: 1200px;
  width: 100%;
  height: auto;
  margin: auto;
  transition: all 0.3s ease-in-out;



  @include mq($until: desktop) {
    max-width: calc(1000px - 32px);
  }

  @include mq($until: tabletoffset) {
    max-width: calc(750px - 16px);

  }

  @include  mq($until: tablet) {
    max-width: 100%;
  }

  &.slider-item-reveal {

    @include mq($until: desktop) {
      --swiffy-slider-item-reveal: 0rem !important;
    }
  }

  .slide-visible {

    @include mq($until: desktop) {
      height: auto;
    }
  }

  .slider-indicators {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    top: -4px;

    @include mq($until: desktop) {
      top: -8px;
    }

    .slider-indicator {
      background-color: var(--amc-grey);
      border: 1px solid var(--amc-grey);
      opacity: 1;
      border-radius: 0;
      flex-grow: 1;

      &.active {
        background-color: var(--amc-brand1);
        border: 1px solid var(--amc-brand1);
      }
    }
  }

  .slider-nav {
    filter: none;
    transform: none;
    opacity: 1;

    &::before {
      background-color: var(--amc-brand1);
    }

    &::after {
      background-color: #fff;
    }
  }

  .slide {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

body[data-contenttype="listing"] {

  .content-image-slideshow {
    padding: 0;
  }


  .swiffy-slider {
    height: fit-content;
    max-width: 1200px;

    .slide-visible {
      height: 100%;
    }

    .slide {
      position: relative;

      img {
        object-fit: cover;
      }
    }

    .slider-nav {
      opacity: 1;

      &::before {
        background-color: var(--amc-brand1);
      }

      &::after {
        background-color: #fff;
      }
    }

    .slider-indicators {
      display: flex;
      position: absolute;
      gap: 6px;
      top: auto;
      bottom: 0;
      left: 2rem;
      right: 2rem;

      .slider-indicator {
        background-color: white;
        border: 1px solid transparent;
        flex-grow: 0;
        border-radius:  50%;

        &.active {
          background-color: transparent;
          border: 1px solid white;
        }
      }

      @include mq($until: phablet) {
        display: inline-flex;
      }

      * {
        opacity: 1;
        margin: auto 0 auto 0;

        &.active {
          background-color: transparent;
          border: .1rem solid white;
        }
      }
    }
  }

  .listing-container .swiffy-slider {
    max-width: 100%;
    margin: 0;
    padding: 0;

    .slide-visible {
      height: 640px;

      @include mq($until: tablet) {
        height: 388px;
      }
    }
  }
}
