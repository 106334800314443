.u-color {
  &-warning {
    color: var(--amc-warning);
  }
}

@each $key, $color in $colors-map {
  .u-color-#{$key} {
    color: var(--amc-#{$color});
  }
}

.u-color-black {
  color: var(--amc-black);
}

.u-color-red {
  color: var(--amc-brand4);
}

.u-color-green {
  color: var(--amc-brand1);
}

.u-color-primary {
  color: var(--amc-brand4);
}

.u-color-secondary {
  color: var(--amc-landingPageHighlight);
}

.u-color-highlight {
  color: var(--amc-landingPageHighlight);
}
