.event-list-day-date {
  @include font-heavy;

  position: relative;
  //padding: 40px 40px 0;
  text-align: center;
  border: 2px solid var(--amc-border);
  height: 0;
  padding: 0;
  padding-bottom: 100%;
  overflow: hidden;

  @include mq($until: tabletoffset) {
    padding-bottom: 0;
    height: $grid-width * 2;

    .event-list-day-date-day {
      position: absolute;
      left: 50%;
      margin: 0;
      top: $grid-width * 0.25;
      font-size: $grid-width * 0.5;
    }

    .event-list-day-date-month {
      position: absolute;
      left: 50%;
      bottom: $grid-width * 0.25;
      font-size: $grid-width * 0.5;
    }

    .event-list-day-date-number {
      position: absolute;
      left: 50%;
      margin: 0;
      transform: translate(-100%, -50%) translateX(- $grid-width * 0.25);
      font-size: $grid-width * 1.5;
      top: 50%;
      line-height: 1;
    }

    .event-list-day-date-arrows {
      position: relative;
      height: 100%;
    }

    .event-list-day-date-next, .event-list-day-date-prev {
      height: 100%;
      border-top: none;
      border-bottom: none;
    }
  }

  @include mq($until: phablet) {
    padding-bottom: 0;
    height: $grid-width + $grid-width * 0.25;

    .event-list-day-date-day {
      margin: 0;
      top: $grid-width * 0.25;
      font-size: $grid-width * 0.25;
      transform: translateX(-10px);
    }

    .event-list-day-date-month {
      bottom: $grid-width * 0.25;
      font-size: $grid-width * 0.25;
      transform: translateX(-10px);
    }

    .event-list-day-date-number {
      transform: translate(-100%, -50%) translateX(-20px);
      font-size: $grid-width - 10px;
    }

    .event-list-day-date-arrows {
      //
    }

    .event-list-day-date-next, .event-list-day-date-prev {
      width: $grid-width * 0.5;
      //height: 100%;
      //border-top: none;
      //border-bottom: none;
    }
  }
}

.event-list-day-date-day {
  font-size: 30px;
  color: var(--amc-brand4);
  margin-top: $grid-width * 0.25;
}

.event-list-day-date-number {
  font-size: 115px;
  margin-top: 30px;
  line-height: 1;
}

.event-list-day-date-month {
  font-size: 30px;
}

.event-list-day-date-arrows {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.event-list-day-date-next,
.event-list-day-date-prev {
  width: $grid-width;
  height: $grid-width;
  display: inline-block;
  color: var(--amc-border);
  border: 2px solid var(--amc-border);
  cursor: pointer;
  padding: 0;
  background-color: var(--amc-white);

  .icon {
    width: $size-chevrons;
    height: $size-chevrons;
    margin: auto;
    display: block;
  }

  &:hover {
    .icon {
      fill: var(--amc-menu);
    }
  }
}

.event-list-day-date-next {
  float: right;
  margin: 0 -2px -2px 0;
}

.event-list-day-date-prev {
  float: left;
  margin: 0 0 -2px -2px;
}

.event-list-day-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.event-list-day-item {
  @include font-heavy;

  padding: $grid-width * 0.25 0;
  display: block;
  border-bottom: 2px solid var(--amc-border);
  overflow: hidden;

  //&:hover {
  //  border-bottom-color: var(--amc-focus);
  //}
}

.event-list-day-time {
  font-size: 20px;
}

.event-title-link {
  color: var(--amc-text);

  &:hover,
  &:active {
    color: var(--amc-menu);
    text-decoration: none;
  }

  &:last-child {
    .event-list-day-title {
      margin-bottom: 0;
    }
  }
}

.event-list-day-title {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 26px, 40px);

  margin-bottom: $grid-width *0.25;
}

.event-list-day-location {
  margin-top: 8px;
  font-size: 18px;
  color: var(--amc-brand1);

  @include font-medium;

  .icon-col {
    width: $grid-width * 0.5;
    height: $grid-width * 0.5;
    display: inline-block;
    float: left;
  }

  .text-col {
    display: inline-block;
    float: left;
  }
}
