h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: $grid-width * 0.5;
}

p {
  @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 15px, 25px);

  margin-top: 0;
}

h1,
.h1 {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 30px, 60px);

  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 1.5px;
}

h2,
.h2 {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 25px, 40px);

  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 1px;
}

h3,
.h3 {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 20px, 32px);

  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 1px;
}

h4,
.h4 {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 17px, 26px);

  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1px;
}

h5,
.h5 {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 15px, 22px);

  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1px;
}

h6,
.h6 {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 13px, 20px);

  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1px;
}

.content p {
  //@include fluid-type(margin-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 15px, 90px);

  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }
}
