/**
  Settings – used with preprocessors and contain font, colors definitions, etc.
  Tools – globally used mixins and functions. It’s important not to output any CSS in the first 2 layers.
  Generic – reset and/or normalize styles, box-sizing definition, etc. This is the first layer which generates actual CSS.
  Elements – styling for bare HTML elements (like H1, A, etc.). These come with default styling from the browser so we can redefine them here.
  Objects – class-based selectors which define undecorated design patterns, for example media object known from OOCSS
  Components – specific UI components. This is where majority of our work takes place and our UI components are often composed of Objects and Components
  Utilities – utilities and helper classes with ability to override anything which goes before in the triangle, eg. hide helper class
*/

// SETTINGS
@import 'settings/colors';
@import 'settings/breakpoints';
@import 'settings/fonts';
@import 'settings/other';
@import 'settings/sizes';

// TOOLS
@import "../../node_modules/sass-mq/mq";
@import 'tools/mixins';

// GENERIC
@import 'generic/base';
@import 'generic/typo';
@import 'generic/colors';

// ELEMENTS
@import "elements/links";
@import 'elements/buttons';
@import 'elements/inputs';

// OBJECTS
//@import "../../node_modules/bootstrap/dist/css/bootstrap-grid";
@import "../../node_modules/swiper/dist/css/swiper.css";
@import "../../node_modules/flickity/css/flickity.css";
@import "../../node_modules/swiffy-slider/dist/css/swiffy-slider.css";
//@import "../../node_modules/bulma/sass/utilities/_all";
//@import "../../node_modules/bulma/sass/grid/columns";
@import "objects/layout";
@import "objects/grid-helpers";
@import "objects/grid";
@import "objects/icons";
@import "objects/modal";

// COMPONENTS
@import 'components/site-header';
@import 'components/main-navigation';
@import 'components/site-search';
@import 'components/site-main';
@import 'components/site-footer';
@import 'components/event-list-day';
@import 'components/quote';
@import 'components/sliders';
@import 'components/map';
@import 'components/cards';
@import "components/cards-news";
@import 'components/accordion';
@import 'components/checks';
@import "components/filters";
//@import 'components/store-details';
//@import 'components/store-categories';
//@import 'components/content-videos';
@import 'components/content-video';
//@import 'components/cookienotice';
@import "components/listing-base-info";
@import "components/events/event-header";
@import "components/events/event-base-info";
@import "components/events/event-list";
@import "components/home-teaser-slider";
@import "components/home-teaser-grid";
@import "components/discover-list";
@import "components/discover-map";
@import "components/logowall";
@import "components/search";
@import "components/divider-headline";
@import "components/vbcn/vbcn";
@import "components/contact-info";
@import "components/search-results";
@import "components/manual-schedule";
@import "components/slideshow-navigation";
@import "components/emergency-modal";

// Templates for specific pages
@import 'templates/landing-pages';

// UTILITIES
@import 'utilities/u-text';
@import 'utilities/u-align';
@import 'utilities/u-color';
@import 'utilities/u-full-width';
@import 'utilities/u-full-height';
@import 'utilities/u-margin';
@import 'utilities/u-padding';
@import 'utilities/u-fonts';
@import 'utilities/u-clearfix';
@import 'utilities/u-sizer';
@import 'utilities/u-hide-show';
@import 'utilities/u-responsive-tag';
@import 'utilities/SHAME';
