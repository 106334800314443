h1, h2, h3, h4, h5, h6, .alpha, .beta, .gamma, .delta, .epsilon, .zeta {
  //
}

.u-font-light {
  @include font-light;
}

.u-font-medium {
  @include font-medium;
}

.u-font-bold {
  @include font-bold;
}

.u-font-heavy {
  @include font-heavy;
}

.u-font-quote {
  @include font-quote;
}
