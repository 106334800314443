.text-input {
  position: relative;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  display: block;
  //cursor: pointer;
  padding: $baseline * 0.5;
  border: 2px solid var(--amc-border);
}
