.container-fluid {
  max-width: $layout-width;
}

.container {
  max-width: $grid-max-width;
  margin: 0 auto;
  padding: 0 $baseline * 0.5;

  @include mq($until: tablet) {
    padding: 0 $baseline * 0.5;
  }
}
