.search-word {
  .search-word-label {
    //
  }

  .search-word-input {
    @include font-heavy;

    padding-right: $grid-width;
    width: 100%;
    background: var(--amc-white);
    //border: none;
    //display: inline-block;
    float: right;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  //.search-word-button {
  //  width: $grid-width / 2;
  //  height: $grid-width;
  //  //color: var(--amc-white);
  //  //background: var(--amc-brand3);
  //  //border: none;
  //  //display: inline-block;
  //  //float: right;
  //  cursor: pointer;
  //
  //  &:hover {
  //    //background: var(--amc-brand3);
  //    background: var(--amc-brand1);
  //  }
  //
  //  @include mq($from: tablet) {
  //    width: $grid-width;
  //    float: left;
  //  }
  //}

  .search-word-button {
    position: absolute;
    font-size: 20px;
    top: 0;
    right: 0;
    width: 52px;
    height: 100%;
    border: 2px solid var(--amc-border);
    content: '';
    text-align: center;
    line-height: 50px;
    display: flex;
    cursor: pointer;
    background: transparent;

    .icon {
      width: $grid-width * 0.5;
      height: $grid-width * 0.5;
      margin: auto;
      display: block;
      //fill: var(--amc-border);
      stroke: var(--amc-border);
      fill: none;
    }

    &:hover {
      //background: var(--amc-brand3);
      background: var(--amc-border);

      .icon {
        stroke: var(--amc-brand4);
      }
    }
  }
}

.group-filters {
  font-size: 14px;
  border: 2px solid var(--amc-border);
  border-bottom: none;
}

.filter-group {
  position: relative;
  //padding: 15px 50px 15px 15px;
  text-transform: uppercase;
  cursor: pointer;
  overflow: hidden;

  &.is-active {
    .filter-group-head {
      color: var(--amc-brand4);

      .icon-h {
        .icon {
          transform: rotate(180deg);
        }
      }
    }

    .filter-group-list {
      display: block;
    }
  }

  &:hover {
    .filter-group-head {
      color: var(--amc-brand4);

      .icon-h {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.filter-group-head {
  @include font-heavy;

  position: relative;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  padding: $baseline * 0.5 50px $baseline * 0.5 $baseline * 0.5;
  border-bottom: 2px solid var(--amc-border);

  .icon-h {
    position: absolute;
    font-size: 20px;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    border-left: 2px solid var(--amc-border);
    content: '';
    text-align: center;
    line-height: 50px;
    display: flex;

    .icon {
      width: $size-chevrons;
      height: $size-chevrons;
      margin: auto;
      display: block;
      fill: var(--amc-border);
    }
  }
}

.filter-group-list {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  border-bottom: 2px solid var(--amc-border);
  display: none;
}

.filter-group-list-item {
  @include font-bold;

  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  padding: $baseline * 0.25 50px $baseline * 0.25 $baseline * 0.5;

  .icon-h {
    position: absolute;
    font-size: 20px;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    content: '';
    text-align: center;
    line-height: 50px;
    display: flex;

    .icon {
      width: $size-chevrons;
      height: $size-chevrons;
      margin: auto;
      display: none;
    }
  }

  &.is-active {
    color: var(--amc-brand4);

    .icon-h {
      .icon {
        display: block;
      }
    }
  }

  &:hover {
    color: var(--amc-brand4);
  }
}

.filter-single {
  font-size: 14px;
  border: 2px solid var(--amc-border);
  border-bottom: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.filter-single-item {
  @include font-heavy;

  position: relative;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  padding: $baseline * 0.5 50px $baseline * 0.5 $baseline * 0.5;
  border-bottom: 2px solid var(--amc-border);

  .icon-h {
    position: absolute;
    font-size: 20px;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    border-left: 2px solid var(--amc-border);
    content: '';
    text-align: center;
    line-height: 50px;
    display: flex;

    .icon {
      width: $size-chevrons;
      height: $size-chevrons;
      margin: auto;
      //display: block;
      display: none;
    }
  }

  &.is-active {
    color: var(--amc-brand1);

    .icon-h {
      .icon {
        display: block;
      }
    }
  }

  &:hover {
    color: var(--amc-brand1);
  }
}
