.search-results {
  .highlight {
    color: var(--amc-menu);
    font-style: normal;
  }
}
.search-results-search-label {
  margin-bottom: 10px;
  display: block;
}
.search-results-search-submit-button {

}

.search-results-lists {}
.search-results-group {
  .results-group-title {

  }
  .result-group-entries {

  }
  .result-entry {
    padding: 25px 0;
    border-bottom: 2px solid var(--amc-borders);

    .highlight {
      color: var(--amc-menu);
    }
    .result-pretitle {
      @include font-bold;

      font-size: 17px;
      letter-spacing: 0.5px;
    }

    .result-title {
      display: block;
      color: var(--amc-text);
    }

    .result-pretitle + .result-title,
    .result-pretitle + .result-title-link .result-title {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
