.col {
  &-1,
  &-2,
  &-3,
  &-4,
  &-5,
  &-6,
  &-7,
  &-8,
  &-9,
  &-10,
  &-11,
  &-12,
  &-auto,
  &-sm-1,
  &-sm-2,
  &-sm-3,
  &-sm-4,
  &-sm-5,
  &-sm-6,
  &-sm-7,
  &-sm-8,
  &-sm-9,
  &-sm-10,
  &-sm-11,
  &-sm-12,
  &-sm-auto,
  &-md-1,
  &-md-2,
  &-md-3,
  &-md-4,
  &-md-5,
  &-md-6,
  &-md-7,
  &-md-8,
  &-md-9,
  &-md-10,
  &-md-11,
  &-md-12,
  &-md-auto,
  &-lg-1,
  &-lg-2,
  &-lg-3,
  &-lg-4,
  &-lg-5,
  &-lg-6,
  &-lg-7,
  &-lg-8,
  &-lg-9,
  &-lg-10,
  &-lg-11,
  &-lg-12,
  &-lg-auto,
  &-xl-1,
  &-xl-2,
  &-xl-3,
  &-xl-4,
  &-xl-5,
  &-xl-6,
  &-xl-7,
  &-xl-8,
  &-xl-9,
  &-xl-10,
  &-xl-11,
  &-xl-12,
  &-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: $grid-gutter-width * 0.25;
    padding-left: $grid-gutter-width * 0.25;

    @media (min-width: $md) {
      padding-right: $grid-gutter-width * 0.5;
      padding-left: $grid-gutter-width * 0.5;
    }
  }

  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: $grid-gutter-width * 0.25;
  padding-left: $grid-gutter-width * 0.25;

  @media (min-width: $md) {
    padding-right: $grid-gutter-width * 0.5;
    padding-left: $grid-gutter-width * 0.5;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;

  &-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  &-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  &-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  &-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  &-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  &-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  &-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  &-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  &-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  &-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  &-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  &-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  &-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: $sm) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    &-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }

    &-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }

    &-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }

    &-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    &-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    &-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    &-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    &-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    &-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    &-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }

    &-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media (min-width: $md) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    &-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }

    &-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }

    &-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }

    &-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    &-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    &-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    &-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    &-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    &-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    &-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }

    &-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media (min-width: $lg) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    &-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }

    &-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }

    &-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }

    &-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    &-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    &-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    &-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    &-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    &-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    &-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }

    &-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media (min-width: $xl) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    &-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }

    &-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }

    &-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }

    &-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    &-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    &-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    &-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    &-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    &-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    &-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }

    &-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
