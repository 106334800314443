.accordion {
  font-size: 14px;
  border: 2px solid var(--amc-border);
  border-bottom: none;
}

.accordion-item {
  position: relative;
  padding: 15px 50px 15px 15px;
  text-transform: uppercase;
  border-bottom: 2px solid var(--amc-border);
  cursor: pointer;
  overflow: hidden;

  @include font-heavy;

  span {
    display: inline-block;
    float: left;
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    padding: 12px;
    display: inline-block;
    float: right;
    border-left: 2px solid var(--amc-border);
    color: var(--amc-grey);
  }

  &.is-clicked {
    color: var(--amc-brand1);

    svg {
      transform: rotate(180deg);
      border-right: 2px solid var(--amc-border);
      border-left: none;
    }
  }
}

.accordion-list {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  border-bottom: 2px solid var(--amc-border);
  display: none;

  &.is-visible {
    display: block;
  }

  li {
    position: relative;
    font-size: 15px;
    padding: 5px 20px 5px 15px;
    display: block;
    cursor: pointer;

    &:after {
      font-size: 20px;
      position: absolute;
      top: 1px;
      right: 15px;
    }

    &.is-active {
      color: var(--amc-brand1);

      &:after {
        content: '\2713';
      }
    }

    @include font-bold;
  }
}

.accordions-list {
  border: 2px solid var(--amc-borders);
  border-top: none;
}

.accordions-item {
  background: var(--amc-white);

  &.is-active {
    .accordions-head {
      background: var(--amc-menu);
      color: var(--amc-white);

      &:after {
        position: absolute;
        bottom: -18px;
        left: calc(50% - 9px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 18px 18px 0;
        border-color: var(--amc-menu) transparent transparent;
        content: '';
      }
    }

    .accordions-content {
      display: block;
    }
  }

  &:hover {
    .accordions-head {
      background: var(--amc-landingPageAccordionsHover);

      &:after {
        border-color: var(--amc-landingPageAccordionsHover) transparent transparent;
      }
    }
  }
}

.accordions-head {
  @include fluid-type(padding, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 20px, 45px);
  @include font-heavy;

  position: relative;
  font-size: 20px;
  color: var(--amc-text);
  background: var(--amc-white);
  border-top: 2px solid var(--amc-borders);
  cursor: pointer;
}

.accordions-content {
  @include font-medium;

  font-size: 18px;
  color: var(--amc-text);
  //display: none;
  height: 0;
  overflow: hidden;

  p:last-child {
    margin-bottom: 0;
  }
}

.accordions-content-inner {
  @include fluid-type(padding, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 20px, 45px);
}
