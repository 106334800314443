.u-hide {
  display: none !important;
}

.u-hide--s {
  @include mq($until: phablet) {
    display: none !important;
  }
}

.u-hide--m {
  @include mq($until: tablet) {
    display: none !important;
  }
}

.u-hide--mb {
  @include mq($until: tabletoffset) {
    display: none !important;
  }
}

.u-hide--d {
  @include mq($until: desktop) {
    display: none !important;
  }
}

.u-hide--wd {
  @include mq($until: widedesktop) {
    display: none !important;
  }
}

.u-hide--w {
  @include mq($until: wide) {
    display: none !important;
  }
}

.u-hide--s-up {
  @include mq($from: phablet) {
    display: none !important;
  }
}

.u-hide--m-up {
  @include mq($from: tablet) {
    display: none !important;
  }
}

.u-hide--d-up {
  @include mq($from: desktop) {
    display: none !important;
  }
}

/**
 * Hide content off-screen without resorting to `display:none;`, also provide
 * breakpoint specific hidden elements.
 */
.u-visuallyhidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}
