.partner-link {
  display: block;
  margin-bottom: $grid-width * 0.5;
  text-align: center;

  &:hover {
    opacity: 0.7;
  }
}

.partners {
  width: 100%;
}
