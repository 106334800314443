@mixin event-list-schedules {
  background-color: var(--amc-boxBackground);
  position: relative;

  .schedule,
  .person {
	//@include fluid-type(padding-top padding-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 30px);
	//@include fluid-type(padding-right padding-left, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 34px);
	@include fluid-type(padding-left, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 34px);

	border-top: 2px solid var(--amc-border);
	display: flex;
	//background-color: var(--amc-landingPageBorders);
    background-color: rgba(map-get($colors-map, 'landingPageBorders'), 0.5);

	//flex: 1;
	//
	//@include mq($until: map-get($mq-breakpoints, 'tablet')) {
	//  order: 2;
	//}

	&.show-more-schedules,
	&.add-more-people {
	  @include font-bold;

	  display: block;
	  background: none;
	  width: 100%;
	  padding: $baseline * 0.5;
	  outline: none;
	  border: none;
	  border-top: 2px solid var(--amc-border);
	  cursor: pointer;
	  font-size: 1em;
	  text-transform: uppercase;
	}

	@include mq($until: tablet) {
	  flex-wrap: wrap;
	  padding-left: 0;
	}
  }

  .schedule-date,
  .person-firstname {
	font-size: 22px;
	padding: $baseline * 0.5;
	line-height: $baseline;
	//flex: 0 0 300px;

	@include mq($until: tablet) {
	  //padding: $baseline / 4 $baseline / 2;
	  //padding-bottom: 0;
	  padding-left: 0;
	  flex-basis: 100%;
	}
  }

  .schedule-date {
	//flex: 0 0 500px;
	font-weight: bold;
	//line-height: 50px;
	display: flex;
	flex-direction: column;

	.date-inner {
	  margin: auto 0;
	}

	@include mq($until: tablet) {
	  padding-left: 20px;
	}
  }

  .schedule-time,
  .person-lastname {
	padding: $baseline * 0.5;

	@include fluid-type(padding-right padding-left, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 50px);

	@include mq($until: tablet) {
	  padding: $baseline * 0.25 $baseline * 0.5;
	  padding-top: 0;
	}
  }

  .schedule-actions,
  .person-actions {
	//padding: $baseline / 2;
	flex-basis: 40%;
	margin-left: auto;
	display: flex;
	flex-direction: column;

	.btn-tertiary {
	  margin: auto 0;
	  //line-height: 50px;
	  height: 100%;
	  background-color: var(--amc-white);
	  border-left: 2px solid var(--amc-border);
	  color: var(--amc-text);
	  padding-left: $baseline * 0.5;
	  //padding-right: $baseline / 2;
	  display: flex;
	  align-items: center;
	  transition: all $transition-fx $transition-time;
	  transition-property: background-color color;
	  justify-content: space-between;
	  text-align: left;

	  .icon-h {
		width: 50px;
		height: 100%;
		text-align: center;
		line-height: 50px;
		display: flex;
		margin-left: $baseline 7 4;
		color: var(--amc-focus);
		transition: color $transition-fx $transition-time;

		.icon {
		  width: $size-chevrons;
		  height: $size-chevrons;
		  margin: auto;
		  display: block;
		  fill: currentColor;
		}
	  }

	  &:hover,
	  &:focus {
		background-color: var(--amc-landingPageButton);
		color: var(--amc-white);

		.icon-h {
		  color: var(--amc-white);
		}
	  }

	  &.hover-switch {
		.just-added,
		.remove {
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  width: 100%;
		}

		.remove {
		  display: none;
		}

		.just-added {
		  display: flex;
		}

		&:hover,
		&:focus {
		  .remove {
			display: flex;
		  }

		  .just-added {
			display: none;
		  }
		}
	  }
	}

	.btn-download {
	  padding: 0;
	  background: none;
	  border: none;
	  border-left: 2px solid var(--amc-border);
	  display: flex;

	  &:hover {
		.btn-download-icon {
		  background-color: var(--amc-focus);
		}
	  }
	  .btn-download-icon {
		position: static;
		//background: none;
		border: none;
		width: 64px;
		height: 64px;
	  }
	}

	@include mq($until: tablet) {
	  flex-basis: 100%;

	  .btn-tertiary {
		border-left: none;
		border-top: 2px solid var(--amc-border);
		height: $baseline * 2;
	  }


	  .btn-download {
		border-left: none;
		border-top: 2px solid var(--amc-border);

		.btn-download-icon {
		  width: 100%;
		  text-align: center;
		}
	  }
	}
  }
}
