.emergency-modal {

  .modal__title {
    color: var(--amc-landingPageLink);
  }

  strong, b, i, em {
    color: var(--amc-landingPageLink);
  }

  .modal__btn-primary {
    background: var(--amc-landingPageMenu);
    color: var(--amc-white);

    &:hover {
      background: var(--amc-landingPageMenuActive);
    }

    @include mq($until: tablet) {
      padding: 12px
    }
  }

  .modal__btn-secondary {
    background: var(--amc-landingPageLink);
    color: var(--amc-white);

    &:hover {
      background: var(--amc-landingPageMenuActive);
    }

    @include mq($until: tablet) {
      padding: 12px
    }
  }

  .modal__container {
    width: 100%;
  }

  .modal__footer {
    display: flex;
    gap: $grid-width * 0.25;
    justify-content: space-between;

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }
}
