.am-discover-list {
  .map {
    left: 0;
    width: 100%;
  }

  .filter-container {
    @include mq($until: tablet) {
      display: none;

      &.is-visible {
        display: block;
      }
    }
  }

  .filter-show-toggle {
    @include font-heavy;

    position: relative;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    display: none;
    cursor: pointer;
    padding: $baseline * 0.5 50px $baseline * 0.5 $baseline * 0.5;
    border: 2px solid var(--amc-border);

    .icon-h {
      position: absolute;
      font-size: 20px;
      top: 0;
      right: 0;
      width: 50px;
      height: 100%;
      border-left: 2px solid var(--amc-border);
      content: '';
      text-align: center;
      line-height: 50px;
      display: flex;

      .icon {
        width: $size-chevrons;
        height: $size-chevrons;
        margin: auto;
        display: block;
        fill: var(--amc-border);
      }
    }

    @include mq($until: tablet) {
      display: block;
    }

    &.is-filter-visible {
      margin-bottom: $baseline;

      .icon-h {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .filter-group,
  .filter-single {
    &:last-child {
      margin-bottom: $baseline;
    }
  }

  .list-header {
    display: flex;

    .results-headline {
      display: block;
      flex-basis: 100%;
    }

    .toggle-map {
      //outline: 2px solid var(--amc-border);
      padding: $baseline * 0.5 $baseline;
      display: flex;
      background: none;
      border: 2px solid var(--amc-border);
      align-content: flex-start;
      align-items: center;
      cursor: pointer;
      margin-bottom: $baseline;

      .text {
        @include font-heavy;

        text-align: left;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        margin-right: $baseline * 0.5;
      }

      .icon-h {
        width: $baseline * 2;
        text-align: center;
        flex-shrink: 0.5;

        .icon {
          margin: auto;
          width: 100%;
        }
      }

      &:hover,
      &:active {
        background-color: var(--amc-hover-background);
      }
    }

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  .info-bar {
    //position: absolute;
    //left: 0;
    //bottom: 0;
    width: 100%;
    display: flex;
    color: var(--amc-white);
    background: var(--amc-brand5);
    padding: $grid-width * 0.125 50px;
    align-items: center;

    @include fluid-type(padding-left padding-right, map-get($mq-breakpoints, 'tabletoffset'), map-get($mq-breakpoints, 'desktop'), 20px, 50px);

    a {
      color: var(--amc-white);
      text-decoration: underline;
    }

    .icon-col {
      padding: 0;
      width: $grid-width;
      //float: left;
      //margin-right: $grid-width;
    }

    .icon {
      max-width: $grid-width * 0.25 * 3;
      max-height: $grid-width * 0.25 * 3;
      fill: var(--amc-white);
    }

    .text-col {
      font-weight: bold;
      display: inline-block;
      margin-left: $grid-width * 0.25;
    }

    .info-bar-col {
      width: 48%;
      margin-right: 4%;

      &:last-child {
        margin-right: 0;
      }

      @include mq($until: phablet) {
        width: 100%;
        margin: 0;
      }
    }
  }

  .mapboxgl-marker:not(.mapboxgl-user-location-dot) {
    background-image: url('/assets/img/markers/pins/food.svg');
  }

  .discover-list-entry {
    width: 100%;
    margin: 0 0 $baseline;
    outline: 2px solid var(--amc-border);
  }

  .card-stories.regular-layout .discover-list-entry .card-story {
    float: none;
    margin-bottom: 0;
    outline: none !important;

    @include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
      margin: 0 !important;

      &.card-story-more {
        height: auto;
        min-height: 0;
      }
    }

    &.card-story-more {
      flex-direction: column;

      .text {
        @include font-heavy;

        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        color: var(--amc-black);
        text-transform: uppercase;
        display: block;
      }
    }
  }

  .card-story-image {
    @include mq($until: 'phablet') {
      overflow: hidden;
    }

    .image-image {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .discover-list-places {
    background-color: var(--amc-boxBackground);
    position: relative;

    .place {
      border-top: 2px solid var(--amc-border);
      display: flex;

      &.show-more-places {
        @include font-bold;

        display: block;
        background: none;
        width: 100%;
        padding: $baseline * 0.5;
        outline: none;
        border: none;
        border-top: 2px solid var(--amc-border);
        cursor: pointer;
        font-size: 1em;
        text-transform: uppercase;
      }

      @include mq($until: tablet) {
        flex-wrap: wrap;
      }
    }

    .place-name {
      padding: $baseline * 0.5;
      flex: 0 0 300px;

      @include mq($until: tablet) {
        padding: $baseline * 0.25 $baseline * 0.5;
        padding-bottom: 0;
        flex-basis: 100%;
      }
    }

    .place-opening-hours {
      padding: $baseline * 0.5;

      @include fluid-type(padding-right padding-left, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 50px);

      @include mq($until: tablet) {
        padding: $baseline * 0.25 $baseline * 0.5;
        padding-top: 0;
      }
    }
  }
}
