.site-footer {
  @include fluid-type(padding-top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 25px, 50px);
  @include fluid-type(padding-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 50px, 100px);

  overflow: hidden;
}

.footlogo {
  position: relative;
  //overflow: hidden;

  &:before {
    position: absolute;
    top: 50%;
    width: 100%;
    border-bottom: 2px solid var(--amc-brand2);
    z-index: 1;
    content: '';
  }

  &:after {
    position: absolute;
    top: 50%;
    width: 50vw;
    border-bottom: 2px solid var(--amc-brand2);
    z-index: -1;
    content: '';
    left: 100%;
  }
}

.footlogo-image {
  @include fluid-type(padding, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 15px, 50px);
  @include fluid-type(width, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 240px, 260px);

  position: relative;
  max-width: calc(100% - 30px);
  height: 70px;
  margin: 0 auto;
  background: var(--amc-white);
  box-sizing: content-box;
  display: block;
  z-index: 2;
}

.footlogo-span {
  @include fluid-type(top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 80%, 70%);

  position: absolute;
  width: 200px;
  height: 50px;
  right: calc(50% - 250px);
  z-index: 3;

  @include mq($until: map-get($mq-breakpoints, 'phablet')) {
    display: none;
  }
}

.site-footer-logo {
  position: relative;

  &:before {
    position: absolute;
    top: 35px;
    width: 100%;
    height: 1px;
    background: red;
    content: '';
  }
}

.footsocials-list {
  @include fluid-type(margin-top, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tablet'), 10px, 1px);
  @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tablet'), 20px, 40px);

  padding: 0;
  list-style: none;
  text-align: center;
}

.footsocials-item {
  display: inline;
}

.footsocials-link {
  width: 27px;
  height: 27px;
  margin: 4px;
  color: #9b9b9b;
  display: inline-block;
}

.footcol-title {
  @include fluid-type(letter-spacing, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 0.1px, 1.2px);
  @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 10px, 27px);

  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
}

.footcol-list {
  margin: 0 0 30px;
  padding: 0;
  list-style: none;
}

.footcol-item {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.1px;
  display: block;
}

.footcol-link {
  padding: 4px 0;
  color: var(--amc-black);
  display: block;

  &:hover {
    //color: var(--amc-link);
    color: var(--amc-link);
    text-decoration: none;
  }
}
