@charset "UTF-8";

@font-face{
  font-family:"Quickpen W05";
  src:url("../fonts/1560996/76c02472-2f52-43e1-a7ca-98605f0e41e4.woff2") format("woff2"),url("../fonts/1560996/b03f9e76-83e3-4a01-a642-b0085ce9d73d.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face{
  font-family:"Quickpen W05";
  src:url("../fonts/5323166/024556e3-921b-4ead-95eb-02bf7d822d45.woff2") format("woff2"),url("../fonts/5323166/5ebc23a5-a3f1-4d78-b68b-6db5ffeffafc.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face{
  font-family:"Uni Neue W05";
  src:url("../fonts/6013998/260ebd1f-36c4-4748-9620-9a51797cd15f.woff2") format("woff2"),url("../fonts/6013998/19373563-3de8-4d89-aa6d-cc12bc5044db.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face{
  font-family:"Uni Neue W05";
  src:url("../fonts/6013994/37954e74-bd7e-4f65-9667-663c8d7413fa.woff2") format("woff2"),url("../fonts/6013994/34cae571-dac6-4678-9107-dff5486c095b.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face{
  font-family:"Uni Neue W05";
  src:url("../fonts/5504057/337b0d5d-e363-4a1e-aeff-1ec762964925.woff2") format("woff2"),url("../fonts/5504057/35babcda-fcd7-41c0-b63c-c3895e8043d5.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face{
  font-family:"Uni Neue W05";
  src:url("../fonts/6014009/78531fa5-865f-4da8-a87d-5ca985a9e69d.woff2") format("woff2"),url("../fonts/6014009/8d40486d-a203-48dd-af94-404b486a3162.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face{
  font-family:"Uni Neue W05";
  src:url("../fonts/6014004/051689be-b9df-43d9-bb52-587342d2aace.woff2") format("woff2"),url("../fonts/6014004/7d8081cb-edeb-43c3-8547-a3684f4b3a7b.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face{
  font-family:"Uni Neue W05";
  src:url("../fonts/6014001/13911ce6-4aba-4f5a-965e-d36d497e4149.woff2") format("woff2"),url("../fonts/6014001/e51e83f9-c762-4bd2-a4e7-24356c397be0.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

// Font families
$ff1: 'Uni Neue W05', helvetica, arial, sans-serif;
$ff2: 'Quickpen W05';

@mixin font-light {
  font-family: $ff1;
  font-weight: 700;
}

@mixin font-medium {
  font-family: $ff1;
  font-weight: 400;
}

@mixin font-bold {
  font-family: $ff1;
  font-weight: 700;
}

@mixin font-heavy {
  font-family: $ff1;
  font-weight: 900;
}

@mixin font-quote {
  font-family: $ff2;
  font-weight: 700;
}
