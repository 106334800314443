@import 'shared';

$time-unit: $grid-width;

.event-list {
  //.days {
  //  padding: 0;
  //  border: 2px solid var(--amc-borders);
  //  border-bottom: none;
  //  text-align: right;
  //  display: flex;
  //
  //  .days-nav-prev {
  //    width: $grid-width;
  //    cursor: pointer;
  //    border: none;
  //    border-right: 2px solid var(--amc-borders);
  //    background-color: var(--amc-white);
  //
  //    .icon {
  //      width: $size-chevrons;
  //    }
  //
  //    &:hover {
  //      .icon {
  //        fill: var(--amc-focus);
  //      }
  //    }
  //  }
  //
  //  .days-nav-next {
  //    width: $grid-width;
  //    cursor: pointer;
  //    border: none;
  //    border-left: 2px solid var(--amc-borders);
  //    background-color: var(--amc-white);
  //
  //    .icon {
  //      width: $size-chevrons;
  //    }
  //
  //    &:hover {
  //      .icon {
  //        fill: var(--amc-focus);
  //      }
  //    }
  //  }
  //
  //  .days-list {
  //    //max-width: $grid-width * 2 * 7;
  //    overflow: hidden;
  //    white-space: nowrap;
  //    position: relative;
  //    width: calc(100% - #{$grid-width * 2});
  //    height: $grid-width * 2;
  //  }
  //
  //  .days-holder {
  //    position: absolute;
  //  }
  //
  //  .inputgroup {
  //    display: inline-block;
  //    height: $grid-width * 2;
  //    border-left: 2px solid var(--amc-borders);
  //    width: $grid-width * 2;
  //    text-align: center;
  //    vertical-align: middle;
  //    position: relative;
  //    max-width: calc(100% / 3);
  //
  //    &.is-disabled {
  //      color: var(--amc-borders);
  //    }
  //
  //    @include mq($until: tablet) {
  //      &:first-child {
  //        border-left: none;
  //      }
  //    }
  //
  //    .day-input {
  //      display: none;
  //
  //      &:hover + .day-label,
  //      &:checked + .day-label {
  //        color: var(--amc-focus);
  //
  //        //.month:after {
  //        //  border-color: var(--amc-focus);
  //        //}
  //      }
  //    }
  //
  //    .day-label {
  //      vertical-align: middle;
  //      position: absolute;
  //      top: 50%;
  //      left: 50%;
  //      transform: translate(-50%, -50%);
  //      cursor: pointer;
  //
  //      .day-name {
  //        color: var(--amc-borders);
  //      }
  //
  //      .day {
  //        font-size: 3em;
  //        font-weight: 900;
  //        line-height: 1em;
  //        display: block;
  //      }
  //
  //      .month {
  //        display: inline-block;
  //        //font-size: 16px;
  //        position: relative;
  //
  //        //&:after {
  //        //  content: '';
  //        //  border-top: 1px solid transparent;
  //        //  position: absolute;
  //        //  bottom: - $grid-width / 8;
  //        //  left: 50%;
  //        //  width: 180%;
  //        //  transform: translateX(-50%);
  //        //}
  //      }
  //    }
  //  }
  //}


  .vdp-datepicker__calendar {
    width: 100%;

    .cell.selected {
      background-color: var(--amc-menu);
    }

    .cell:not(.blank):not(.disabled).day:hover, .cell:not(.blank):not(.disabled).month:hover, .cell:not(.blank):not(.disabled).year:hover {
      background-color: var(--amc-landingPageMenuActive);
      border-color: var(--amc-landingPageMenuActive);
    }
  }

  .categories {
    padding: $grid-width * 0.5 $grid-width;
    border: 2px solid var(--amc-borders);

    .inputgroup {
      position: relative;
    }

    .categories-select {
      width: 100%;
      height: $grid-width;
      border: 2px solid var(--amc-borders);
      border-radius: 0;
      appearance: none;
      padding: $grid-width * 0.25;
      font-size: 18px;
      color: var(--amc-focus);
      position: relative;
      padding-right: $grid-width + $grid-width * 0.25;
    }

    .categories-select-arrow {
      position: absolute;
      width: $grid-width;
      height: $grid-width;
      right: 0;
      top: 0;
      border-left: 2px solid var(--amc-borders);
      pointer-events: none;

      .icon {
        width: 24px;
        transform: translate(-50%, -50%);
        position: absolute;
        fill: var(--amc-borders);
        top: 50%;
        left: 50%;
      }
    }

    @include mq($until: tablet) {
      padding: 0;
      border: none;
      margin-bottom: $grid-width * 0.25;
    }
  }



  //.event-list-events {
  //  position: relative;
  //  padding-bottom: $grid-width;
  //  border-left: 2px solid var(--amc-borders);
  //
  //  &:before {
  //    content: "";
  //    position: absolute;
  //    width: 100%;
  //    top: 0;
  //    border-top: 2px solid var(--amc-borders);
  //  }
  //
  //  @include mq($until: tablet) {
  //    margin-left: $grid-width;
  //
  //    &:before {
  //      display: none;
  //    }
  //  }
  //}

  //.event-list-event {
  //  position: relative;
  //  padding-top: $grid-width * 0.5;
  //  padding-bottom: $grid-width * 0.5;
  //  //padding-left: $grid-width;
  //  min-height: $time-unit * 2;
  //  max-height: $time-unit * 10;
  //  display: flex;
  //  flex-wrap: wrap;
  //
  //  //@for $i from 1 through 40 {
  //  //  &.tu-#{$i} {
  //  //    height: $time-unit * $i;
  //  //  }
  //  //}
  //  &:after {
  //    content: "";
  //    position: absolute;
  //    width: 100%;
  //    bottom: -2px;
  //    left: 0;
  //    border-bottom: 2px solid var(--amc-borders);
  //  }
  //
  //  //&:hover {
  //  //  .event-info-container {
  //  //    &:after {
  //  //      border-bottom-color: var(--amc-focus);
  //  //    }
  //  //  }
  //  //}
  //
  //  .event-image-container {
  //    max-width: $grid-width * 6;
  //    width: 40%;
  //    margin-right: $grid-width;
  //
  //    @include mq($until: tablet) {
  //      width: 100%;
  //      margin-right: 0;
  //      max-width: none;
  //    }
  //  }
  //
  //  .event-image {
  //    display: block;
  //  }
  //
  //  .event-info-container {
  //    position: relative;
  //    width: calc(60% - #{$grid-width});
  //    //padding-bottom: $grid-width / 2;
  //
  //    @include mq($until: tablet) {
  //      padding-top: $grid-width * 0.125;
  //      width: 100%;
  //    }
  //  }
  //
  //  .event-time {
  //    @include font-heavy;
  //
  //    color: var(--amc-text);
  //
  //    @include mq($until: tablet) {
  //      display: block;
  //      //padding: $grid-width / 16 $grid-width / 4;
  //      //background: var(--amc-borders);
  //      //margin-bottom: $grid-width / 8;
  //    }
  //  }
  //
  //  //.event-starttime {
  //  //  position: absolute;
  //  //  width: $grid-width;
  //  //  left: -$grid-width;
  //  //  //top: 0;
  //  //  font-size: $grid-width / 4;
  //  //  color: var(--amc-borders);
  //  //  top: - $grid-width / 4;
  //  //  line-height: $grid-width / 2;
  //  //  height: $grid-width / 2;
  //  //  text-align: right;
  //  //  padding-right: $grid-width / 4;
  //  //
  //  //  @include mq($until: tablet) {
  //  //    top: 0;
  //  //  }
  //  //}
  //  //
  //  //.event-endtime {
  //  //  position: absolute;
  //  //  width: $grid-width;
  //  //  left: -$grid-width;
  //  //  //bottom: 0;
  //  //  font-size: $grid-width / 4;
  //  //  color: var(--amc-borders);
  //  //  bottom: - $grid-width / 4;
  //  //  line-height: $grid-width / 2;
  //  //  height: $grid-width / 2;
  //  //  text-align: right;
  //  //  padding-right: $grid-width / 4;
  //  //  display: none;
  //  //}
  //
  //  .event-title {
  //    @include mq($until: tablet) {
  //      margin-bottom: $grid-width * 0.25;
  //    }
  //  }
  //
  //  .event-subtitle {
  //    display: none;
  //  }
  //
  //  .event-description {
  //    //padding-right: $grid-width * 4;
  //  }
  //
  //  .event-location {
  //    display: flex;
  //    margin-bottom: $grid-width * 0.5;
  //
  //    .icon-col {
  //      width: $grid-width;
  //      //text-align: center;
  //
  //      .icon {
  //        max-width: $grid-width * 0.25 * 3;
  //        //margin-top: 50%;
  //        fill: var(--amc-link);
  //      }
  //
  //      @include mq($until: tablet) {
  //        display: none;
  //      }
  //    }
  //
  //    .text-col {
  //      @include mq($until: tablet) {
  //        color: var(--amc-link);
  //      }
  //    }
  //
  //    @include mq($until: tablet) {
  //      margin-bottom: $grid-width * 0.25;
  //    }
  //  }
  //
  //  .event-link {
  //    position: absolute;
  //    right: 0;
  //    top: $grid-width * 0.5;
  //  }
  //
  //  &:last-child {
  //    .event-endtime {
  //      display: block;
  //    }
  //  }
  //
  //  @include mq($until: tablet) {
  //    padding-top: 0;
  //    padding-left: 0;
  //    background-color: #fff;
  //    margin-bottom: $grid-width * 0.5;
  //
  //    //.event-title,
  //    //.event-subtitle,
  //    //.event-description,
  //    //.event-link {
  //    //  padding: 0;
  //    //  margin-left: $grid-width / 4;
  //    //  margin-right: $grid-width / 4;
  //    //}
  //
  //    .event-description {
  //      //display: none;
  //    }
  //
  //    .event-link {
  //      position: relative;
  //      top: auto;
  //      right: auto;
  //      margin-top: 0;
  //    }
  //  }
  //}
}

.event-list {

  //.event-list-event {
  //  width: 100%;
  //  margin: 0 0 $baseline;
  //  outline: 2px solid var(--amc-border);
  //}


  .vdp-datepicker__calendar {
    width: 100%;
  }

  .event-list-entry {
    width: 100%;
    margin: 0 0 $baseline;
    outline: 2px solid var(--amc-border);
  }

  .event-list-event {
    float: none;
    display: flex;
    //margin-bottom: $baseline;
    //outline: 2px solid var(--amc-border);
    align-items: center;

    .card-story-image {
      flex-shrink: 0;
      //margin-right: $baseline;
      flex-basis: 200px;

      @include mq($until: 'phablet') {
        overflow: hidden;
      }

      .image-image {
        display: block;
      }
    }

    .card-story-content {
      @include fluid-type(padding-top padding-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 30px);
      @include fluid-type(padding-right padding-left, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 50px);

      flex: 1;

      @include mq($until: map-get($mq-breakpoints, 'tablet')) {
        order: 2;
      }
    }

    .event-title-link {
      display: block;
    }

    .card-title {
      font-size: 30px;
    }

    .event-actions {
      margin-top: $baseline;
	  display: flex;
	  gap: $grid-width * 0.5;

	  @include mq($until: tablet) {
		gap: $grid-width * 0.25;
		flex-direction: column;
	  }
      .btn-primary {
        //margin-right: $baseline;
      }
    }

    &.card-story {
      margin-bottom: 0;
    }
  }

  .event-location {
	display: flex;
	margin-bottom: $grid-width * 0.5;

	.icon-col {
	  width: $grid-width;

	  .icon {
		max-width: $grid-width * 0.25 * 3;
		fill: var(--amc-link);
	  }

	  //@include mq($until: tablet) {
		//display: none;
	  //}
	}

	.text-col {
	  //@include mq($until: tablet) {
		//color: var(--amc-link);
	  //}
	}

	@include mq($until: tablet) {
	  margin-bottom: $grid-width * 0.25;
	}
  }

  .event-list-schedules {
	@include event-list-schedules;
  }
}
