@mixin button {
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  //border-radius: $radius-small;
  font-size: 20px;
  line-height: 1;
  padding: 22px $baseline;
  //background-color: $brand-green;
  display: inline-block;
  //color: $color-text;
  transition: box-shadow $transition-time $transition-fx;
  border: none;

  &[disabled] {
    //pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.btn-share {
  color: var(--amc-grey);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &-right {
    justify-content: flex-end;
  }

  @include font-medium;

  &-icon {
    width: 48px;
    height: 48px;
    display: inline-block;
  }

  span {
    display: inline-block;
  }
}

.btn-primary {
  @include button;

  position: relative;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
  background: var(--amc-brand1);
  color: var(--amc-white);

  @include font-heavy;

  &:hover {
    background: var(--amc-brand3);
    text-decoration: none;
  }
}

.btn-secondary {
  position: relative;
  margin-top: 30px;
  margin-bottom: 10px;
  color: var(--amc-black);
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;

  @include font-heavy;

  &:after {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid currentColor;
    content: '';
  }

  &:hover {
    color: var(--amc-brand1);
    text-decoration: none;
  }
}

.btn-download {
  position: relative;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 1.1px;
  padding: 20px 80px 20px 0;
  display: block;
  color: var(--amc-black);
  border-bottom: 2px solid var(--amc-border);

  &:hover {
    text-decoration: none;
    color: var(--amc-black);
    border-color: var(--amc-brand1);

    .btn-download-icon {
      border-color: var(--amc-brand1);
    }
  }
}

.btn-download-icon {
  position: absolute;
  right: 0;
  bottom: -2px;
  width: $grid-width - 2;
  height: $grid-width - 2;
  padding: $grid-width * 0.25;
  fill: var(--amc-brand4);
  color: var(--amc-brand4);
  background: var(--amc-white);
  border: 2px solid var(--amc-border);
}

.cta-button {
  @include font-heavy;

  position: relative;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 1em;
  background: none;
  outline: none;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  height: 200px;
  width: 100%;
  display: flex;
  margin-top: 50px;
  padding-bottom: 30px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--amc-focus);
  color: var(--amc-focus);
  text-decoration: none;

  &:hover {
    background-color: var(--amc-focus);
    color: var(--amc-white);
    text-decoration: none;
  }

  .button-inner {
    position: relative;
    margin-top: 30px;
    margin-bottom: 10px;
    color: currentColor;
    text-transform: uppercase;
    display: inline-block;
    font-size: 1em;
    background: none;
    border: none;
    outline: none;
    padding-left: 0;
    padding-right: 0;

    &:after {
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      border-bottom: 2px solid currentColor;
      content: '';
    }
  }
}
