.home-teaser-grid {
  .teaser-tiles {
    display: flex;
    flex-flow: row nowrap;

    @include mq($until: widedesktop) {
      flex-wrap: wrap;
    }

    @include mq($until: tablet) {
      //flex-flow: column nowrap;
    }

    //&.more-cats {
    //  flex-flow: column wrap;
    //  height: 604px;
    //
    //  @include mq($until: map-get($mq-breakpoints, 'widedesktop')) {
    //    flex-direction: row;
    //    height: auto;
    //  }
    //
    //  .store-cat {
    //    width: calc(25% - 2px);
    //    height: 300px;
    //
    //    @include mq($until: map-get($mq-breakpoints, 'widedesktop')) {
    //      flex: 0 0 calc(50% - 2px);
    //    }
    //
    //    @include mq($until: map-get($mq-breakpoints, 'tablet')) {
    //      flex: 0 0 calc(100% - 2px);
    //    }
    //
    //    &:first-child {
    //      height: 602px;
    //
    //      @include mq($until: map-get($mq-breakpoints, 'widedesktop')) {
    //        height: 300px;
    //        flex: 0 0 calc(100% - 2px);
    //      }
    //    }
    //  }
    //}

    &.has-5,
    &.has-6,
    &.has-7,
    &.has-8 {
      flex-wrap: wrap;

      .teaser-tile {
        height: auto;

        &:before {
          content: '';
          float: left;
          padding-top: 100%;

          @include mq($until: tablet) {
            padding-top: 66%;
          }
        }
      }
    }

    &.has-6 {
      .teaser-tile {
        //width: calc(100% / 3 - 2px);
        //max-width: calc(100% / 3 - 2px);

        &:before {
          padding-top: 66%;
        }
      }
    }
  }

  .teaser-tile {
    //background: tomato;
    position: relative;
    height: 500px;
    //display: flex;
    //flex-flow: column wrap;
    //justify-content: center;
    margin: 0 1px 2px;
    overflow: hidden;
    //width: 25%;
    //flex: 0 0 calc(25% - 2px);
    flex: 1 0 auto;
    width: calc(25% - 2px);
    //max-width: calc(25% - 2px);
    display: flex; // make us of Flexbox
    align-items: center; // does vertically center the desired content
    justify-content: center; // horizontally centers single line items
    text-align: center; // optional, but helps horizontally center text that breaks into multiple lines

    @include mq($until: tablet) {
      width: 100%;
    }

    @include mq($until: widedesktop) {
      flex: 1 0 calc(50% - 2px);
      //height: 300px;
    }

    @include mq($until: tablet) {
      flex: 1 0 100%;
    }

    .teaser-tile-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center center;
      background-size: cover;
      transition: transform map-get($timing, 'duration');

      // removed for stadtsommer
      //&:before {
      //  position: absolute;
      //  top: 0;
      //  right: 0;
      //  bottom: 0;
      //  left: 0;
      //  background: rgba(#000, 0.5);
      //  content: '';
      //}
    }

    &:hover {
      text-decoration: none;

      .teaser-tile-image {
        transform: scale(1.1, 1.1);
      }
    }

    .teaser-tile-info {
      position: relative;
      color: var(--amc-white);
      text-align: center;
      padding: 25px;
      // https://github.com/philipwalton/flexbugs#flexbug-2
      max-width: 100%;
    }

    .teaser-tile-title {
      font-size: 36px;
      text-transform: uppercase;
      margin: 0;

      @include font-heavy;
    }

    .teaser-tile-text {
      font-size: 16px;
      letter-spacing: 0.3px;
      display: block;
      margin-top: 7px;

      @include font-medium;
    }
  }
}

@supports (display: grid) {
  .home-teaser-grid {
    .teaser-tiles {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2px;

      @include mq($until: widedesktop) {
        grid-template-columns: repeat(2, 1fr);
      }
      //
      @include mq($until: tablet) {
        grid-template-columns: 1fr;
      }
      //

      @include mq($from: widedesktop) {
        &.has-5 {
          .teaser-tile {
            &:nth-child(1) {
              grid-row: 1 / 3;
            }

            &:nth-child(2) {
              grid-row: 1 / 3;
            }

            &:nth-child(3) {
              grid-row: 1 / 3;
            }
          }
        }

        &.has-6 {
          grid-template-columns: repeat(3, 1fr);

          .teaser-tile {
            &:before {
              padding-top: 66%;
            }
          }
        }

        &.has-7 {
          .teaser-tile {
            &:nth-child(1) {
              grid-row: 1 / 3;
            }
          }
        }

        &.has-8 {
          //
        }
      }
    }

    .teaser-tile {
      //background: tomato;
      //color: white;
      //font-weight: bold;
      //font-size: 1.5em;
      //text-align: center;
      // margin: 5px;
      width: auto;
      max-width: none;
      margin: 0;

      @include mq($until: tablet) {
        //width: 100%;
      }

      @include mq($until: widedesktop) {
        //flex: 0 0 calc(50% - 2px);
        //height: 300px;
      }

      //&:after {
      //  content: "";
      //  display: block;
      //  padding-bottom: 100%;
      //}
    }

    //.teaser-item {
    //  width: auto;
    //  max-width: none;
    //  margin: 0;
    //
    //  &:first-child {
    //    grid-row: 1 / 3;
    //    background: blue;
    //  }
    //}
  }
}
