.discover-map-outer {
  //background: red;

  .map-container,
  .discover-map {
    width: calc(100% + #{$grid-width * 0.5});
    margin-left: - $grid-width * 0.25;
    margin-top: $grid-width * 0.25;

    //@include mq($from: tablet) {
    //  width: calc(100% + #{$grid-width });
    //  margin-left: - $grid-width / 2;
    //}
  }

  &:not(.is-map-initialized) .map {
    position: relative;
    height: calc(100vh - 200px);
    max-height: 2000px;
    width: 100%;
    left: 0;
    background-color: var(--amc-map-background);
  }

  .maphead {
    border: 2px solid var(--amc-border);
  }

  .maphead-inner {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    //padding-right: $grid-width;
    //padding-left: $grid-width;

    @include mq($until: tabletoffset) {
      width: 100%;
      display: block;
      padding: 0;
    }
  }

  .maphead-text.maphead-text--filter {
    @include mq($from: tabletoffset) {
      border-left: none;
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .maphead-text {
    @include font-heavy;

    font-size: 13px;
    letter-spacing: 1px;
    //width: 145px;
    //height: 145px;
    padding: 10px;
    float: left;
    text-align: center;
    color: var(--amc-borders);
    text-transform: uppercase;
    border-left: 2px solid var(--amc-border);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }

  .maphead-text--filter {
    font-size: 18px;
    letter-spacing: 1.3px;
    text-align: left;
    color: var(--amc-text);
    width: auto;
    padding: $grid-width * 0.5;
    cursor: default;
    padding-left: $grid-width;

    @include mq($until: tabletoffset) {
      width: 100%;
      padding: $grid-width * 0.25;
      line-height: $grid-width * 0.5;
      display: block;
      border: none;
      text-align: left;
      font-size: $grid-width * 0.25;
    }

    @include mq($until: 350px) {
      font-size: 14px;
    }
  }

  .maphead-button {
    @include mq($until: tabletoffset) {
      width: 100%;
      height: $grid-width;
      flex-direction: row;
      justify-content: flex-start;
      border-left: none;
      border-bottom: 2px solid var(--amc-border);
    }

    .icon-h {
      @include mq($from: tabletoffset) {
        height: auto;
      }
    }
  }

  .maphead-button-categories {
    float: right;
  }

  .maphead-button-text {
    @include mq($until: tabletoffset) {
      display: inline-block;
      min-height: 0;
      margin-top: 0;
      text-align: left;
    }
  }

  .maphead-categories {
    @include mq($from: tabletoffset) {
      border-right: 2px solid var(--amc-border);
    }
  }

  .mapboxgl-map {
    //width: 100%;
    position: relative;
    height: calc(100vh - 200px);
    max-height: 2000px;
    width: 100%;
    left: 0;

    @include mq($until: tablet) {
      height: calc(100vh - 170px);
    }

    //@include mq($until: 'desktop') {
    //  width: calc(100% - 350px);
    //  left: 350px;
    //}
    //
    //@include mq($until: 'tabletoffset') {
    //  width: 100%;
    //  left: 0;
    //}
  }

  //.mapboxgl-marker:not(.mapboxgl-user-location-dot) {
  //  //background-image: url('/assets/img/markers/pins/default.svg');
  //  background-image: none;
  //  background-repeat: no-repeat;
  //  background-position: center center;
  //  background-size: contain;
  //  width: $grid-width;
  //  height: $grid-width;
  //  //border-radius: 50%;
  //  cursor: pointer;
  //
  //  .icon {
  //    //display: none;
  //    display: block;
  //    //fill: var(--amc-focus);
  //  }
  //}

  .mapboxgl-marker:not(.mapboxgl-user-location-dot).is-active {
    width: $grid-width * 1.5;
    height: $grid-width * 1.5;
    margin-top: - $grid-width * 0.25; // account for marker offset
    z-index: 1;

    .icon {
      //
    }
  }

  .mapinfo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 550px;
    padding: 0;
    padding-right: $grid-width * 0.5;
    padding-bottom: $grid-width * 0.5;
    //padding: 30px 30px 30px 0;
    background: var(--amc-white);
    z-index: 10;
    overflow-y: auto;
    margin: 0;

    @include mq($until: tabletoffset) {
      //position: relative;
      //top: auto;
      //bottom: auto;
      //left: auto;
      //overflow-y: initial;
      //margin: 40px 0;
      //padding: 0;

      padding-right: 0;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      margin: 0;
      width: 100%;
      max-width: none;

      .mapinfo-content {
        padding: $grid-width * 0.25;
        padding-bottom: 0;
      }

      .mapinfo-head,
      .mapinfo-image,
      .mapinfo-address,
      .mapinfo-contact,
      .mapinfo-text {
        display: none;
      }

      .btn-secondary {
        @include mq($until: phablet) {
          margin-top: 0;
        }
      }
    }

    @include mq($until: phablet) {
      padding-bottom: $grid-width * 0.25;
    }

    //@include mq($until: tablet) {
    //  top: auto;
    //  bottom: 0;
    //  left: 0;
    //  right: 0;
    //  width: 100%;
    //  transform: none!important;
    //  z-index: 10;
    //}
  }

  .mapinfo-head {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .mapinfo-image {
    padding-top: 0;
    margin-bottom: $grid-width * 0.5;

    .image-image {
      display: block;
    }
  }

  .mapinfo-close {
    flex-flow: row nowrap;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    font-size: 1em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    border-radius: 0;
    padding: 0;
    margin-top: $grid-width * 0.25;
    margin-bottom: $grid-width * 0.25;
    cursor: pointer;

    @include font-heavy;

    .close-text {
      margin-right: $grid-width * 0.125;
    }

    .icon-h {
      width: $grid-width * 0.5;
      height: $grid-width * 0.5;
    }

    &:hover,
    &:focus {
      color: var(--amc-menu);
    }
  }

  .mapinfo-category {
    &.tag-culture {
      color: var(--amc-tag-culture);
    }

    &.tag-default {
      color: var(--amc-tag-default);
    }

    &.tag-dress {
      color: var(--amc-tag-dress);
    }

    &.tag-family {
      color: var(--amc-tag-family);
    }

    &.tag-food {
      color: var(--amc-tag-food);
    }

    &.tag-townhall {
      color: var(--amc-tag-townhall);
    }

    &.tag-parking {
      color: var(--amc-tag-parking);
    }
  }

  .mapinfo-title {
    @include fluid-type(font-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 30px, 40px);
    @include font-heavy;

    margin-bottom: $grid-width * 0.25;
  }

  .mapinfo-address {
    @include font-bold;
  }

  .mapinfo-contact {
    @include font-bold;

    margin-bottom: $grid-width * 0.5;

    .info-website {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }

  .mapinfo-text {
    margin-bottom: $grid-width * 0.5;
  }
}
