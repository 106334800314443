.landingpage {
  h1, h2, h3, h4 {
    color: var(--amc-landingPageHighlight);
    font-weight: 900;
  }

  #main {
    max-width: 100vw;
    overflow: hidden;
  }

  .content p {
    @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 15px, 90px);

    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: var(--amc-landingPageHighlight);
    text-decoration: none;

    &:hover {
      color: var(--amc-landingPageMenuActive);
    }
  }

  .btn-secondary {
    color: var(--amc-landingPageMenu);

    &:hover {
      color: var(--amc-landingPageMenuActive);
    }
  }

  .partner-link {
    display: block;
    margin-bottom: $grid-width * 0.5;
    text-align: center;

    &:hover {
      opacity: 0.7;
    }
  }

  .ctcc {
    position: relative;
  }

  .cc-dismiss {
    &:hover {
      color: var(--amc-landingPageHighlight);
    }
  }

  .partners {
    width: 100%;
  }

  .content-title-container {
    position: absolute;
    top: 50%;
    width: 100%;
    left: 0;
    transform: translateY(-50%);
  }

  .content-title {
    @include fluid-type(padding-right padding-left, map-get($mq-breakpoints, 'tabletoffset'), map-get($mq-breakpoints, 'desktop'), 20px, $grid-gutter-width * 2);

    margin: 0;
    width: 100%;

    .overline {
      color: var(--amc-landingPageHighlight);
      font-size: $grid-width * 0.25;
      text-transform: uppercase;
    }

    .title {
      @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 24px, 64px);

      height: auto;
      max-height: calc(1em * 1.2 * 2);
      overflow: hidden;
      color: var(--amc-text);
      text-transform: uppercase;
      word-break: break-all;

      @include mq($until: 'phablet') {
        max-height: calc(1em * 1.2 * 4);
      }
    }
  }

  .detail-page-backlink {
    color: var(--amc-landingPageMenu);

    &:after {
      border-color: currentColor;
    }
  }
}

.masthead {
  &.landing {
    @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'desktop'), 50px, 100px);

    position: relative;
    width: 100%;
    height: 75vh;
    background-color: #233d60;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;

    &.landing-with-img {
      margin-bottom: $grid-gutter-width * 3.5;
      height: 60vh;

      @include mq($until: 'tabletoffset') {
        margin-bottom: $grid-gutter-width * 2.5;
      }

      @include mq($until: 'phablet') {
        margin-bottom: $grid-gutter-width * 2;
      }
    }

    &.is-low {
      height: 50vh;

      .landing-image {
        max-height: 300px;
        top: 60%;

        @include mq($from: 'tabletoffset', $until: 'desktop') {
          max-height: 240px;
        }
      }
    }

    .landing-inner {
      width: 100%;
      max-width: $layout-width;
      margin-right: auto;
      margin-left: auto;
      padding-right: $grid-width * 0.25;
      padding-left: $grid-width * 0.25;
      display: block;

      @include mq($until: map-get($mq-breakpoints, 'tablet')) {
        padding: 0;
      }
    }

    .landing-logo {
      @include fluid-type(top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 0, 30px);

      position: absolute;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      margin-right: auto;
      margin-left: auto;
      padding: 10px;
      display: block;
      z-index: 7;

      @include mq($until: map-get($mq-breakpoints, 'tablet')) {
        width: $grid-width * 2.5;
        height: $grid-width;

        .icon {
          margin-top: 3px;
          width: 100%;
        }
      }

      .icon {
        fill: #fff;

        &:hover {
          fill: var(--amc-landingPageMenu);
        }
      }

      &-left {
        position: relative;
        left: auto;
        transform: translateX(0);
        margin-left: 0;
        padding-left: 0;
        float: left;

        @include mq($until: map-get($mq-breakpoints, 'tablet')) {
          padding-left: $grid-width * 0.25;
        }
      }

      .logo {
        @include fluid-type(height, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 36px, 72px);
      }
    }

    .landing-image {
      @include fluid-type(max-width, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 250px, 440px);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: $grid-width * 0.25;
      //max-width: 450px;
      //max-height: 450px;

      @include mq($from: 'tabletoffset', $until: 'desktop') {
        max-height: 320px;
      }
    }

    .landing-intro {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0 $grid-width * 0.25;

      @include mq($until: map-get($mq-breakpoints, 'tablet')) {
        padding: 0;
      }

      .landing-intro-inner {
        width: 100%;
        max-width: $grid-max-width - $grid-gutter-width * 0.5;
        margin-right: auto;
        margin-left: auto;
        padding: $grid-width * 0.25;
        background-position: center center;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        padding-bottom: 0;
        height: 46vh;
      }
    }

    .landing-arrow {
      position: absolute;
      bottom: $grid-width * 0.25;
      left: 50%;
      width: $grid-width * 0.5;
      height: $grid-width * 0.5;
      margin-left: - $grid-width * 0.25;
      z-index: 6;
    }

    .nav-opener {
      @include fluid-type(margin-top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 0, 30px);
      @include fluid-type(width height, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), $grid-width, 115px);

      background-color: var(--amc-landingPageMenu);
      z-index: 11;

      //@include mq($until: 'tabletoffset') {
      //  width: $grid-width;
      //  height: $grid-width;
      //}

      &:before {
        background-color: var(--amc-landingPageMenu);
      }

      &:hover,
      &.is-opened {
        background-color: var(--amc-landingPageMenuActive);
      }
    }

    .nav-opener-text {
      display: block;
    }

    .landing-navigation {
      @include fluid-type(padding-top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 50px, 145px);

      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      max-width: $layout-width;
      margin-right: auto;
      margin-left: auto;
      padding: 0 $grid-width * 0.25;
      z-index: 7;
      display: none;

      @include mq($until: map-get($mq-breakpoints, 'tablet')) {
        padding: 0;
      }

      &-inner {
        height: 100%;
        padding: $grid-width * 0.25;
        background-color: var(--amc-landingPageMenu);
        display: flex;
        justify-content: center;
        flex-flow: column nowrap;

        @include mq($until: map-get($mq-breakpoints, 'phablet')) {
          padding-top: $grid-width;
          height: 100vh;
        }
      }
    }

    .landing-menu {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tablet'), 20px, 32px);
        @include fluid-type(padding-top padding-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 5px, 10px);
        @include font-heavy;

        display: block;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.05em;
        line-height: 1.2;
        margin-bottom: 0.5em;

        @include mq($until: map-get($mq-breakpoints, 'phablet')) {
          text-align: left;
        }

        @media (max-height: 800px) {
          font-size: 25px;
          padding-top: 0;
          padding-bottom: 0;
        }

        a {
          color: var(--amc-white);
          padding: 0;
          display: block;

          &:hover {
            color: var(--amc-landingPageMenuActive);
            text-decoration: none;
          }
        }
      }
    }

    .landing-socials {
      margin: $grid-width * 0.5 0 0;
      padding: 0;
      list-style: none;
      text-align: center;

      @include mq($until: map-get($mq-breakpoints, 'phablet')) {
        text-align: left;
      }

      .social-link {
        margin: 5px;
        width: 27px;
        height: 27px;
        display: inline-block;
        color: var(--amc-white);

        .icon {
          width: $grid-width * 0.5;
          height: $grid-width * 0.5;
          display: block;
        }

        &:hover {
          color: var(--amc-landingPageMenuActive);
        }
      }
    }
  }

  .landing-inner {
    width: 100%;
    max-width: $layout-width;
    margin-right: auto;
    margin-left: auto;
    padding-right: $grid-width * 0.25;
    padding-left: $grid-width * 0.25;
    display: block;

    @include mq($until: map-get($mq-breakpoints, 'tablet')) {
      padding: 0;
    }
  }
}

// FOOTER
.site-footer.landing {
  padding-top: 100px;
  background-color: var(--amc-landingPageBackground);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  @include mq($until: map-get($mq-breakpoints, 'phablet')) {
    padding-top: 50px;
    display: flex;
    flex-flow: column nowrap;
  }

  .container-fluid {
    order: 1;

    @include mq($until: map-get($mq-breakpoints, 'phablet')) {
      padding-top: 20px;
      order: 2;
    }
  }

  .footcol {
    &-title {
      color: var(--amc-white);

      @include mq($until: map-get($mq-breakpoints, 'phablet')) {
        text-align: center;
      }
    }

    &-item {
      @include mq($until: map-get($mq-breakpoints, 'phablet')) {
        text-align: center;
      }
    }

    &-link {
      color: var(--amc-white);

      &:hover {
        color: var(--amc-landingPageMenuActive);
      }
    }
  }

  .footsocials {
    &-list {
      text-align: left;

      @include mq($until: map-get($mq-breakpoints, 'phablet')) {
        text-align: center;
      }
    }

    &-item {
      display: inline-block;

      @include mq($until: map-get($mq-breakpoints, 'phablet')) {
        display: inline;
      }
    }

    &-link {
      display: block;

      @include mq($until: map-get($mq-breakpoints, 'phablet')) {
        display: inline-block;
      }
    }
  }

  .footlogo {
    order: 2;

    @include mq($until: map-get($mq-breakpoints, 'phablet')) {
      order: 1;
    }

    &:before,
    &:after {
      border: 1px solid var(--amc-white);
      z-index: 10;
    }

    &-pretitle {
      color: var(--amc-white);
      text-align: center;
      display: none;
      margin-bottom: -10px;
      letter-spacing: 0.5px;
      z-index: 9;
      position: relative;

      @include mq($until: map-get($mq-breakpoints, 'phablet')) {
        display: block;
      }
    }

    &-image {
      position: relative;
      z-index: 2;

      @include mq($until: map-get($mq-breakpoints, 'phablet')) {
        width: 180px;
      }

      .icon {
        width: 100%;
        fill: #fff;

        &:hover {
          fill: var(--amc-landingPageMenu);
        }
      }
    }

    &-span {
      z-index: 3;
    }
  }
}

.to-top {
  position: relative;
  width: $grid-width;
  height: $grid-width;
  color: var(--amc-white);
  background-color: var(--amc-landingPageMenu);
  display: inline-block;
  float: right;
  cursor: pointer;

  .icon {
    height: 24px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: currentColor;
  }

  &:hover {
    background-color: var(--amc-landingPageMenuActive);
  }
}
