@import 'shared';

.event-base-info {
  //
}

.event-base-image {
  .content-image {
    margin-bottom: $grid-width;
  }
}

.event-base-data {
  .event-info-row {
    display: flex;

    .icon-col {
      padding: 0;
      width: $grid-width;
      //float: left;
      //margin-right: $grid-width;
    }

    .icon {
      max-width: $grid-width * 0.25 * 3;
      fill: var(--amc-link);
    }

    .text-col {
      font-weight: bold;
    }
  }
}

.event-back-link-container {
  display: inline-flex;
  color: var(--amc-text);
  text-decoration: none;

  //
  //.icon {
  //  max-width: $grid-width;
  //}
  //.link-text {
  //
  //}
  //.detail-page-backlink {
  //  display: inline-flex;

  .btn-secondary {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .icon-col {
    width: $grid-width;

    .icon {
      width: 33%;
      margin-top: 50%;
      fill: var(--amc-text);
    }
  }

  &:hover {
    color: var(--amc-link);
    text-decoration: none;

    .detail-page-backlink {
      color: var(--amc-link);
    }

    .icon {
      fill: var(--amc-link);
    }
  }
}

.event-out-link-container {
  display: inline-flex;
  color: var(--amc-text);
  text-decoration: none;
  float: right;

  //
  //.icon {
  //  max-width: $grid-width;
  //}
  //.link-text {
  //
  //}
  //.detail-page-backlink {
  //  display: inline-flex;

  .btn-secondary {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .icon-col {
    width: $grid-width;
    text-align: right;

    .icon {
      width: 33%;
      margin-top: 50%;
      fill: var(--amc-text);
    }
  }

  &:hover {
    color: var(--amc-link);
    text-decoration: none;

    .detail-page-outlink {
      color: var(--amc-link);
    }

    .icon {
      fill: var(--amc-link);
    }
  }
}

.event-list-schedules {
  @include event-list-schedules;
}

.event-detail-schedules {
  .event-list-schedules {
	border: 2px solid var(--amc-border);

	.schedule {
	  &:first-child {
		border-top: none;
	  }
	}

	.schedule-actions {
	  flex-basis: 0;

	  @include mq($until: tablet) {
		flex-basis: 100%;
	  }
	}

	&.has-more-schedules {
	  .schedule:nth-child(n+6):not(.show-more-schedules) {
		display: none;
	  }
	  //.show-more-schedules {
		//display: flex;
	  //}
	}
	&:not(.has-more-schedules) {
	  .show-more-schedules {
		display: none;
	  }
	}
  }
}

.event-list .event-list-entry .listing-detail-schedules.listing-detail-schedules {
  &.has-more-schedules {
    .schedule:nth-child(n+6):not(.show-more-schedules) {
      display: none;
    }
    //.show-more-schedules {
    //display: flex;
    //}
  }
  &:not(.has-more-schedules) {
    .show-more-schedules {
      display: none;
    }
  }
}
