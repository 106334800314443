.u-text-right {
  text-align: right !important;
}

.u-text-center {
  text-align: center !important;
}

.u-align-left {
  text-align: left !important;
}

.u-align-center {
  text-align: center !important;
}

.u-align-right {
  text-align: right !important;
}
