svg.icon {
  fill: currentColor;
  stroke: none;
  display: inline-block;
  //width: $grid-width;
}

.icon-h {
  //width: 100%;
  height: 100%;
  display: inline-block;
}
