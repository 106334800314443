.card-handwritten-title {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 24px, 40px);

  margin-bottom: 20px;
  z-index: 90;

  @include font-quote;

  @include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
    position: relative;
    top: auto;
    right: auto;
  }
}

.cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.card {
  background: #fff;
  border: 2px solid var(--amc-border);
  flex: 0 0 calc(50% - 35px);

  @include mq($until: map-get($mq-breakpoints, 'desktop')) {
    flex: 0 0 calc(50% - 25px);
  }

  @include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
    flex: 0 0 calc(50% - 15px);
  }

  @include mq($until: map-get($mq-breakpoints, 'tablet')) {
    flex: 0 0 100%;
  }

  &:nth-child(odd) {
    @include fluid-type(margin-top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 70px);

    @include mq($until: map-get($mq-breakpoints, 'tablet')) {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  &:nth-child(even) {
    @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 70px);
  }

  &-content {
    @include fluid-type(padding, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 20px, 60px);

    background: #fff;
  }

  .card-title-link {
    color: var(--amc-text);

    &:hover {
      color: var(--amc-text);
      text-decoration: none;
    }
  }

  .card-pretitle {
    @include font-bold;

    font-size: 17px;
    letter-spacing: 0.5px;
  }

  .card-pretitle + .card-title,
  .card-pretitle + .card-title-link .card-title {
    margin-top: 10px;
  }
}

.card-stories {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 100%;

  &.regular-layout {
    .card-story {
      width: 100%;
      margin: 0 0 30px;

      &:nth-child(1n),
      &:nth-child(2n) {
        .card-story-image {
          order: 1;
        }

        .card-story-content {
          order: 2;
        }
      }
    }
  }
}

.content-block-focus {
  @include mq($until: tablet) {
    margin-bottom: 0;
  }
}

.card-big {
  @include mq($from: tablet) {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    background-position: left top;
    background-repeat: no-repeat;
    border: none;

    @include fluid-type(background-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 95%, 75%);

    .card-image-link,
    .card-image {
      display: none;

      @include mq($until: tablet) {
        display: block;
      }
    }

    .card-content {
      width: 100%;
      max-width: $grid-width * 10;
      margin: $grid-width * 2 0 0 $grid-width * 0.25;
      border: 2px solid var(--amc-border);

      @include mq($until: map-get($mq-breakpoints, 'phablet')) {
        margin-bottom: 0;
      }
    }

    .card-title-link {
      //color: var(--amc-text);

      &:hover {
        //color: var(--amc-text);
        text-decoration: none;
      }
    }

    .section-title {
      margin-top: 30px;
      margin-bottom: 300px;
    }
  }

  @include mq($until: tablet) {
    background-image: none !important;
  }
}

.card-big-multiple {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  background-position: left top;
  background-repeat: no-repeat;

  @include fluid-type(background-size, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 95%, 75%);

  @include mq($until: map-get($mq-breakpoints, 'tablet')) {
    background-image: none !important;
  }

  .card-handwritten-title {
    position: absolute;
    top: 5%;
    right: 5%;

    @include mq($until: map-get($mq-breakpoints, 'tablet')) {
      position: relative;
      top: auto;
      right: auto;
    }
  }
}

.card-story {
  @include fluid-type(width, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 100%, 75%);

  background: #fff;
  margin: 0;
  min-height: 300px;
  outline: 2px solid var(--amc-border);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @include mq($until: map-get($mq-breakpoints, 'tabletoffset')) {
    width: 100%;
    margin: 0 0 20px !important;
  }

  @include mq($until: map-get($mq-breakpoints, 'tablet')) {
    flex-flow: row wrap;
    margin-bottom: 30px;

    .card-image {
      display: none;
    }
  }

  &-image {
    position: relative;
    height: 100%;
    display: flex;
    flex: 0 0 300px;
    background-position: center center;
    background-size: cover;

    @include mq($until: 'phablet') {
      order: 1;
      flex: 0 0 100%;
      height: 0;
      padding-bottom: 75%;
    }
  }

  &:nth-child(1n) {
    @include fluid-type(margin-left, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 0%, 25%);

    float: right;
    margin-right: 0;

    .card-story {
      &-image {
        order: 1;
      }

      &-content {
        order: 2;
      }
    }
  }

  &:nth-child(2n) {
    @include fluid-type(margin-right, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tabletoffset'), 0%, 25%);

    float: left;
    margin-left: 0;

    .card-story {
      &-image {
        order: 2;

        @include mq($until: map-get($mq-breakpoints, 'tablet')) {
          order: 1;
        }
      }

      &-content {
        order: 1;

        @include mq($until: map-get($mq-breakpoints, 'tablet')) {
          order: 2;
        }
      }
    }
  }

  &-content {
    @include fluid-type(padding-top padding-bottom, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 30px);
    @include fluid-type(padding-right padding-left, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'desktop'), 20px, 50px);

    flex: 1;

    @include mq($until: map-get($mq-breakpoints, 'tablet')) {
      order: 2;
    }
  }

  .card-pretitle {
    @include font-bold;

    font-size: 17px;
    letter-spacing: 0.5px;
  }

  .card-title-link {
    color: var(--amc-text);

    &:hover {
      color: var(--amc-text);
      text-decoration: none;
    }
  }

  .card-title {
    margin: 10px 0;
    word-break: break-word;
  }
}

.card-more {
  @include fluid-type(min-height, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tablet') + 1, 50px, 700px);

  padding-bottom: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: var(--amc-brand1);
}

.card-story-more {
  height: 300px;
  margin-top: 50px;
  padding-bottom: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: var(--amc-brand1);
}

.card-spacing {
  position: relative;
  width: 100%;

  @include fluid-type(margin-top, map-get($mq-breakpoints, 'tablet'), map-get($mq-breakpoints, 'tablet') + 1, 1px, 350px);
}

.card-deal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  padding: 15px;
  color: var(--amc-white);
  background: var(--amc-brand4);

  @include font-bold;
}
