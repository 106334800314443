$color-highlight: var(--amc-brand1);
$color-text: var(--amc-text);
$color-text-dimmed: var(--amc-text-disabled);
$color-error: var(--amc-error);

@mixin vbcn-base-style {
  background: #fff;
  color: $color-text;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

@mixin vbcn-button {
  border-radius: 3px;
  background: #535353;
  color: #fff;
  font-size: 1em;
  letter-spacing: 0.5px;
  padding: 0.5em 0.8em;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  display: inline-block;
  font-weight: bolder;
  margin: 0 0 0.5em 0.5em;

  &:hover,
  &:active {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5) inset;
  }

  &:disabled {
    color: #888;
    cursor: default;
  }

  &.primary {
    background: $color-highlight;
    color: #fff;
    font-weight: bolder;
  }
}

@mixin vbcn-text-small {
  font-size: 0.75em;
  color: $color-text-dimmed;
}
