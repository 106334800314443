.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal__container {
  background-color: #fff;
  padding: $grid-width * 0.5;
  max-width: $grid-width * 12;
  max-height: 100vh;
  overflow-y: auto;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal__close:before {
    content: "\2715";
  }
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
}

.modal__close {
  background: transparent;
  border: 0;
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.modal__btn {
  margin: 0;
  will-change: transform;
}

//.modal__btn:focus, .modal__btn:hover {
//  -webkit-transform: scale(1.05);
//  transform: scale(1.05);
//}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

// ************************
//  Demo Animation Style
// ************************
@keyframes mmfadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes mmfadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
  to { transform: translateY(0); }
}

@keyframes mmslideOut {
  from { transform: translateY(0); }
  to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
