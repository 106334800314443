.text.quote {
  text-align: center;
}

.quote-text {
  @include fluid-type(font-size, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 24px, 40px);
  @include fluid-type(margin-bottom, map-get($mq-breakpoints, 'phablet'), map-get($mq-breakpoints, 'tabletoffset'), 5px, 40px);

  color: var(--amc-brand4);

  @include font-quote;
}

.quote-author {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.88;
  letter-spacing: 1.1px;
}
