$layout-width: 1536px;

$grid-columns: 12;
$grid-gutter-width: 64px;
$grid-width: 64px;
$grid-width-unitless: 64;

$grid-max-width: 1536px;
$baseline: 32px;

$column-gap: 64px;

$size-chevrons: 24px;
